import { IEnvironment } from "./environment";

export const devEnv: IEnvironment = {
  env: "development",
  api: {
    protocol: "http",
    host: "localhost",
    port: "8080",
  },
};

import { Filters } from "../../components/filters";
import { Column, Row } from "../../components/grid";
import { PageLayout } from "../../components/page-layout";
import { SearchInput } from "../../components/search-input";
import { Table } from "../../components/table";
import useBasketTasks from "./useBasketTasks";
import { tasksTableHeaders, tableFilters } from "../../entities/ITask";
const BasketTasksScreen = () => {
  const {
    basketTasks,
    loading,
    onRowPress,
    onSearchSubmit,
    onSort,
    onFilterChange,
    total,
    onPageChange,
    onTasksPerPageChange,
  } = useBasketTasks();
  return (
    <PageLayout title={`Basket`}>
      <div className="flex-column gap-2">
        <Row>
          <Column>
            <Filters onFilterChange={onFilterChange} filters={tableFilters} />
          </Column>
          <Column>
            <div className="d-flex flex-row my-1 justify-content-end">
              <SearchInput
                onSearchSubmit={onSearchSubmit}
                placeHolder="Search tasks"
              />
            </div>
          </Column>
        </Row>
        <Row>
          <Column>
            <Table
              sort={{ onSort }}
              loading={loading}
              onRowPress={onRowPress}
              headers={tasksTableHeaders}
              data={basketTasks}
              pagination={{
                totalItems: total,
                onItemsPerPageChange: onTasksPerPageChange,
                onPageChange,
              }}
            />
          </Column>
        </Row>
      </div>
    </PageLayout>
  );
};
export default BasketTasksScreen;

import { Button } from "../../components/button";
import { GenericForm } from "../../components/generic-form";
import { Column, Container, Row } from "../../components/grid";
import { ReverseAuthGuard } from "../../components/guard/ReverseAuthGuard";
import assests from "../../config/assets";
import { loginForm } from "./Login.types";
import { useLogin } from "./useLogin";

export function LoginScreen() {
  const { logIn, loading } = useLogin();
  return (
    <ReverseAuthGuard>
      <Container fluid>
        <Row>
          <Column>
            <div className="d-flex justify-content-center mt-5">
              <div className="p-5 border rounded d-flex flex-column justify-content-center mt-3">
                <img
                  src={assests.images.logo}
                  alt="A1 Logo"
                  className="mb-3 mx-auto"
                  width={120}
                />
                <h4 className="mb-3 text-center">Aurora DMS</h4>
                <GenericForm
                  form={loginForm}
                  onSubmit={logIn}
                  submitControl={(submit) => (
                    <Button
                      isLoading={loading}
                      loader={{ size: "s" }}
                      className="btn btn-primary mt-3 w-100"
                      type="submit"
                    >
                      Log in
                    </Button>
                  )}
                />
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </ReverseAuthGuard>
  );
}

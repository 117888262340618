import { useLocation, useNavigate } from "react-router";
import { useToast } from "../components/toast";
import { RequestOptions, useRequest } from "../modules/request";
import { screens } from "../screens/Navigation.types";
import { useAppDispatch } from "../store";
import { authActions } from "../store/auth/AuthReducer";

export default function useBaseRequest<TData, TPayload>(
  request: (payload: TPayload) => Promise<TData>,
  options: RequestOptions<TData, TPayload> = {}
) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { danger } = useToast();

  const onError = (error: any) => {
    if (error?.statusCode === 401 && error?.message === "Token expired") {
      danger("Session expired. Please log in.");
      dispatch(authActions.setUser(null));
      navigate(screens.login, { state: { returnTo: pathname }, replace: true });
      return;
    }
    if (options.onError) {
      options.onError(error);
      return;
    }
    console.log("request error", error);
    danger(error?.message || "Something went wrong.");
  };

  return useRequest(request, {
    ...options,
    onError,
  });
}

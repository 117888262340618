import { useEffect, useState } from "react";
import { GetTasksByBasketIdDTO, AccountingPeriods } from "../../entities/ITask";
import useBaseRequest from "../../api/BaseRequest";
import TaskGateway from "../../api/gateways/TaskGateway";
import { useToast } from "../../components/toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TableRow } from "../../components/table";
import moment from "moment";
import { Icon } from "../../modules/icon";
import { screens } from "../Navigation.types";
import { QueryFilter } from "../../components/filters";

const useBasketTasks = () => {
  const { danger } = useToast();
  const navigate = useNavigate();
  const onRowPress = (id: number) => {
    navigate(screens.tasks.replace(":id", id.toString()));
  };
  const newLocal = +(useParams<{ id: string }>().id || -1);
  const id = newLocal;
  const [basketTasks, setBasketTasks] = useState<TableRow[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [filters, setFilters] = useState<GetTasksByBasketIdDTO["filters"]>({
    limit: 10,
    skip: 0,
  });
  const { execute: getData, loading } = useBaseRequest(
    TaskGateway.getTasksByBasketId,
    {
      onCompleted: (data) => {
        if (data) {
          const taskTable: TableRow[] = [];
          data.tasks.forEach((item) => {
            taskTable.push([
              item.id,
              AccountingPeriods[item.taskTypeCode],
              /*Add Assigned to and Created by*/
              moment(item.createdAt).format("DD.MM.YYYY"),
              item.status,
              <div>
                <Link to={screens.tasks.replace(":id", item.id.toString())}>
                  <Icon
                    title="See task's info"
                    type="fa-solid fa-circle-info"
                    color="black"
                    size={16}
                  />
                </Link>
              </div>,
            ]);
          });
          setBasketTasks(taskTable);
          setTotal(data.count);
        }
      },
      onError: (error) => {
        danger(error?.message || "Something went wrong.");
      },
    }
  );
  useEffect(() => {
    if (id > -1) getData({ basketId: id, filters });
  }, [id, filters]);

  const [searchInput, setSearchInput] = useState("");
  const onSearchSubmit = (text: string) => {
    if (text) setFilters((prev) => ({ textSearch: text, ...prev }));
  };

  const onSort = (key: string, order: "ASC" | "DESC") => {
    setFilters({
      ...filters,
      sortBy: key,
      order: order.toLocaleLowerCase() as "asc" | "desc",
    });
  };

  const onFilterChange = (queryFilters: QueryFilter[]) => {
    setFilters({ ...filters, myTasks: true });
  };

  const onTasksPerPageChange = (docNum: number) => {
    setFilters({
      ...filters,
      limit: docNum,
    });
  };

  const onPageChange = (page: number) => {
    setFilters({
      ...filters,
      skip: page > 0 ? (page - 1) * (filters.limit || 10) : 10,
    });
  };

  return {
    basketTasks,
    total,
    filters,
    setFilters,
    getData,
    onRowPress,
    loading,
    onSearchSubmit,
    searchInput,
    setSearchInput,
    onSort,
    onFilterChange,
    onPageChange,
    onTasksPerPageChange,
  };
};

export default useBasketTasks;

import {
  CreateDocumentDTO,
  GetDocumentsDTO,
  IDocument,
  IDocumentType,
  ValidateDocumentResponse,
} from "../../entities/IDocument";
import { IMetaDataRule, MetaData } from "../../entities/IMetaData";
import BaseInstance from "../BaseInstance";

const DocumentGateway = {
  getDocumentTypes: async (): Promise<IDocumentType[]> => {
    return BaseInstance.get("/document-types");
  },

  getMetaDataForDocType: async ({
    code,
  }: {
    code: string;
  }): Promise<IMetaDataRule[]> => {
    return BaseInstance.get(`/document-types/${code}/meta-data`);
  },

  createDocument: async ({
    docTypeCode,
    file,
    metaData,
  }: CreateDocumentDTO): Promise<IDocument> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("docTypeCode", docTypeCode);
    formData.append("metaData", JSON.stringify(metaData));
    return BaseInstance.post("/documents", formData, {
      timeout: 180000 /* 3min timeout for large files or slow internet */,
    });
  },

  getDocuments: async ({
    textSearch,
    skip = 0,
    limit = 100,
    filters,
    sort,
  }: GetDocumentsDTO): Promise<{
    count: number;
    documents: IDocument[];
  }> => {
    const params: any = { skip, limit };
    if (!!textSearch) {
      params.textSearch = textSearch;
    }
    let filtersQuery = "";
    if (!!filters) {
      filtersQuery = "?";
      filters.forEach((f, i) => {
        filtersQuery += `&filters=${JSON.stringify(f)}`;
      });
    }
    if (!!sort) {
      params.sort = JSON.stringify(sort);
    }

    return BaseInstance.get(`/documents${filtersQuery}`, { params });
  },

  getDocumentById: async ({ id }: { id: string }): Promise<IDocument> => {
    return BaseInstance.get(`/documents/${id}`);
  },

  getDocumentByIdAndMetaData: async ({
    id,
  }: {
    id: string;
  }): Promise<{ document: IDocument; metaDataRules: IMetaDataRule[] }> => {
    const document = await DocumentGateway.getDocumentById({ id });
    const metaDataRules = await DocumentGateway.getMetaDataForDocType({
      code: document.docTypeCode,
    });
    return { document, metaDataRules };
  },

  validateDocument: async (payload: {
    documentTypeCode: string;
    metaData: MetaData;
  }): Promise<ValidateDocumentResponse> => {
    return BaseInstance.post("/documents/validate", payload);
  },

  updateDocument: async (payload: {
    file?: File;
    metaData: MetaData;
    id: string;
  }): Promise<IDocument> => {
    const formData = new FormData();
    formData.append("metaData", JSON.stringify(payload.metaData));
    if (payload.file) {
      formData.append("file", payload.file);
    }
    return BaseInstance.put(`/documents/${payload.id}`, formData);
  },

  dismissDocument: async ({
    id,
  }: {
    id: string;
  }): Promise<{ success: boolean }> => {
    return BaseInstance.delete(`/documents/${id}`);
  },

  updateDocuments: async ({
    documentIds,
    metaData,
  }: {
    documentIds: number[];
    metaData: MetaData;
  }): Promise<IDocument[]> => {
    return BaseInstance.patch("/documents", { docIds: documentIds, metaData });
  },
};

export default DocumentGateway;

import { useEffect, useState } from "react";
import useBaseRequest from "../../api/BaseRequest";
import BasketGateWay from "../../api/gateways/BasketGateway";
import { useToast } from "../../components/toast";
import {
  BASKET_SUCCESS_MESSAGES,
  IGetBasket,
  createBasketForm,
  updateBasketForm as updateBasketFormDefault,
} from "./Basket.types";

const useBaskets = () => {
  const { success, danger } = useToast();
  const [baskets, setBaskets] = useState<IGetBasket[]>([]);
  useEffect(() => {
    execute({});
  }, []);

  //Create Basket
  const [isVisibleCreateBasketModal, setIsVisibleCreateBasketModal] =
    useState(false);
  const { execute: createBasket, loading: creatingBasket } = useBaseRequest(
    BasketGateWay.createBasket,
    {
      onCompleted: (data) => {
        success(BASKET_SUCCESS_MESSAGES.created);
        setIsVisibleCreateBasketModal(false);
        execute({});
      },
      onError: (error) => {
        danger(error?.message || "Something went wrong.");
      },
    }
  );

  const showCreateBasketModalHandler = () =>
    setIsVisibleCreateBasketModal(true);
  const hideCreateBasketModalHandler = () =>
    setIsVisibleCreateBasketModal(false);

  //Get Baskets
  const { execute, loading } = useBaseRequest(BasketGateWay.getBaskets, {
    autoFetch: true,
    onCompleted: (data) => {
      setBaskets(data.baskets);
    },
    onError: (error) => {
      danger(error?.message || "Something went wrong.");
    },
  });

  //Update Basket
  const [updateBasketID, setUpdateBasketID] = useState<number | null>(null);
  const { execute: updateBasket, loading: updatingBasket } = useBaseRequest(
    BasketGateWay.updateBasket,
    {
      onCompleted: (data) => {
        success(BASKET_SUCCESS_MESSAGES.updated);
        execute({});
        setUpdateBasketID(null);
      },
      onError: (error) => {
        danger(error?.message || "Something went wrong.");
      },
    }
  );
  const hideUpdateBasketModalHandler = () => setUpdateBasketID(null);
  const updateBasketForm = {
    ...updateBasketFormDefault,
    name: {
      ...updateBasketFormDefault.name,
      defaultValue:
        baskets.find((basket) => basket.id === updateBasketID)?.name || "",
    },
    isAssignable: {
      ...updateBasketFormDefault.isAssignable,
      defaultValue:
        baskets.find((basket) => basket.id === updateBasketID)?.isAssignable ||
        false,
    },
    id: {
      ...updateBasketFormDefault.id,
      defaultValue: updateBasketID || 0,
    },
  };

  //Delete Basket
  const [deleteBasketID, setDeleteBasketID] = useState<number | null>(null);
  const { execute: deleteBasketFn, loading: deletingBasket } = useBaseRequest(
    BasketGateWay.deleteBasket,
    {
      onCompleted: (data) => {
        success(BASKET_SUCCESS_MESSAGES.deleted);
        execute({});
        setDeleteBasketID(null);
      },
      onError: (error) => {
        danger(error?.message || "Something went wrong.");
      },
    }
  );

  const hideDeleteBasketModalHandler = () => setDeleteBasketID(null);
  const deleteBasket = () => {
    typeof deleteBasketID === "number" && deleteBasketFn(deleteBasketID);
  };

  //Bookmark Basket
  const { execute: bookmarkBasket } = useBaseRequest(
    BasketGateWay.bookmarkBasket,
    {
      onCompleted: (data) => {
        success(BASKET_SUCCESS_MESSAGES.bookmarked);
        execute({});
      },
      onError: (error) => {
        danger(error?.message || "Something went wrong.");
      },
    }
  );
  const { execute: unbookmarkBasket } = useBaseRequest(
    BasketGateWay.unbookmarkBasket,
    {
      onCompleted: (data) => {
        success(BASKET_SUCCESS_MESSAGES.unbookmarked);
        execute({});
      },
      onError: (error) => {
        danger(error?.message || "Something went wrong.");
      },
    }
  );
  const toggleBookmark = (id: number) => {
    if (baskets.find((item) => item.id === id)?.isBookmarked) {
      unbookmarkBasket(id);
    } else {
      bookmarkBasket(id);
    }
  };

  return {
    //
    getBaskets: execute,
    loadingBaskets: loading,
    baskets,
    //
    createBasketForm,
    isVisibleCreateBasketModal,
    createBasket,
    creatingBasket,
    showCreateBasketModalHandler,
    hideCreateBasketModalHandler,
    //
    isVisibleUpdateBasketModal: typeof updateBasketID === "number",
    setUpdateBasketID,
    updateBasketForm,
    hideUpdateBasketModalHandler,
    hideDeleteBasketModalHandler,
    updateBasket,
    updatingBasket,
    deleteBasket,
    deletingBasket,
    setDeleteBasketID,
    isVisibleDeleteBasketModal: typeof deleteBasketID === "number",
    bookmarkedBaskets: baskets.length
      ? baskets.filter((basket) => basket.isBookmarked)
      : [],
    toggleBookmark,
  };
};

export default useBaskets;

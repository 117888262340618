import {
  CreateBasketDTO,
  IGetBasketApi,
  UpdateBasketDTO,
} from "../../screens/baskets/Basket.types";
import BaseInstance from "../BaseInstance";

const BasketGateWay = {
  createBasket: async (payload: CreateBasketDTO) => {
    return BaseInstance.post("/baskets", payload);
  },
  getBaskets: async (): Promise<IGetBasketApi> => {
    return BaseInstance.get("/baskets?limit=1000");
  },
  updateBasket: async (payload: UpdateBasketDTO) => {
    return BaseInstance.put(`/baskets/${payload.id}`, {
      name: payload.name,
      isAssignable: payload.isAssignable,
    });
  },
  deleteBasket: async (id: number) => {
    return BaseInstance.delete(`/baskets/${id}`);
  },
  bookmarkBasket: async (id: number) => {
    return BaseInstance.post(`/baskets/${id}/bookmark`);
  },
  unbookmarkBasket: async (id: number) => {
    return BaseInstance.delete(`/baskets/${id}/bookmark`);
  },
};

export default BasketGateWay;

import { useLocation, useNavigate } from "react-router";
import { screens } from "../../screens/Navigation.types";
import { useAppDispatch } from "../../store";
import { authActions } from "../../store/auth/AuthReducer";

export function useSideNav() {
  const navigate = useNavigate();
  const location = useLocation();
  const activeRoute = location.pathname.split(screens.root)[1];
  const dispatch = useAppDispatch();

  const logOut = () => {
    dispatch(authActions.setUser(null));
    navigate(screens.login);
  };

  return { navigate, activeRoute, logOut };
}

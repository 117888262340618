import { useParams } from "react-router";
import { Button } from "../../components/button";
import { FilePreview } from "../../components/file-preview";
import { Column, Row } from "../../components/grid";
import { PageLayout } from "../../components/page-layout";
import { Icon } from "../../modules/icon";
import { DocumentDataPreview } from "./DocumentDataPreview";
import { DocumentDataUpdate } from "./DocumentDataUpdate";
import { DocumentDismissModal } from "./DocumentDismissModal";
import { useDocument } from "./useDocument";
import { useUser } from "../../store/auth/AuthSelectors";
import CreateTaskModal from "./task/CreateTaskModal";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export function DocumentScreen() {
  const { id } = useParams();
  const isTaskCreating = useLocation().state?.isTaskCreating || false;
  const user = useUser();
  const {
    document,
    loading,
    windowHeight,
    onUpdateDoc,
    metaDataForm,
    onEdit,
    editing,
    docFile,
    onFileEdit,
    onFileEditCancel,
    onEditCancel,
    showDismissModal,
    openModal,
    closeModal,
    onDismiss,
    updating,
    onCreateTask,
    creatingTask,
    createTaskForm,
    isVisibleCreateTaskModal,
    onStartCreatingTask,
    hideCreateTaskModal,
  } = useDocument(id || "");

  useEffect(() => {
    if (isTaskCreating && metaDataForm) {
      onStartCreatingTask();
    }
  }, [isTaskCreating, metaDataForm]);
  return (
    <PageLayout
      title={`Document #${id}`}
      titleRight={
        document ? (
          <div className="d-flex flex-row align-items-center justify-content-end m-3 mt-4">
            {editing ? (
              <Button className="btn btn-danger" onClick={onEditCancel}>
                <Icon type="fa-solid fa-xmark" size={16} />
                &nbsp; Cancel
              </Button>
            ) : user?.id === document.createdBy?.id ||
              user?.dealerCode === document.dealerCode ? (
              <div className="flex-row gap-2">
                <Button
                  className="btn btn-secondary"
                  onClick={onStartCreatingTask}
                >
                  <Icon type="fa-solid fa-plus" />
                  &nbsp; Create task
                </Button>
                <Button className="btn btn-primary" onClick={onEdit}>
                  <Icon type="fa-solid fa-file-pen" />
                  &nbsp; Edit
                </Button>
                <Button className="btn btn-danger" onClick={openModal}>
                  <Icon type="fa-solid fa-folder-minus" />
                  &nbsp; Delete
                </Button>
                <DocumentDismissModal
                  onDismiss={onDismiss}
                  show={showDismissModal}
                  onCancel={closeModal}
                />
                <CreateTaskModal
                  onDismiss={hideCreateTaskModal}
                  onSubmit={onCreateTask}
                  creating={creatingTask}
                  isVisible={isVisibleCreateTaskModal}
                  createTaskForm={createTaskForm}
                />
              </div>
            ) : undefined}
          </div>
        ) : undefined
      }
    >
      {document ? (
        <Row>
          <Column>
            {docFile ? (
              <div className="d-flex flex-column align-items-center">
                <FilePreview
                  enableCanceling={docFile.edited && editing}
                  onCancel={onFileEditCancel}
                  onFileChosen={onFileEdit}
                  enableEditing={editing}
                  type={docFile.type}
                  url={docFile.uri}
                  name={docFile.name}
                  width={(windowHeight - 250) / 1.4}
                  height={windowHeight - 250}
                />
              </div>
            ) : null}
          </Column>
          <Column>
            <div style={{ maxWidth: (windowHeight - 250) / 1.4 }}>
              {editing ? (
                <DocumentDataUpdate
                  docType={document.docType}
                  metaDataForm={metaDataForm}
                  onUpdate={onUpdateDoc}
                  updating={updating}
                />
              ) : (
                <DocumentDataPreview
                  docType={document.docType}
                  metaDataForm={metaDataForm}
                />
              )}
            </div>
          </Column>
        </Row>
      ) : loading ? null : (
        <h4>No document found.</h4>
      )}
    </PageLayout>
  );
}

import { TableData, TableHeaders } from "../../components/table";
import { Icon } from "../../modules/icon";

export const searchArchiveHeaders: TableHeaders[] = [
  { name: "JMBG/MB", key: "customerIdentityNumber" },
  { name: "MSISDN", key: "msisdn" },
  { name: "Document Number", key: "documentNumber" },
  { name: "Sign Date", key: "signDate" },
  { name: "Category", key: "category" },
  { name: "Type", key: "type" },
  { name: "POS", key: "pos" },
  { name: "Box ID", key: "boxId" },
  { name: "Status", key: "status" },
  { name: "File", key: "file" },
];

export const searchArchiveData: TableData[][] = [
  [
    "1234567891011",
    "060000000",
    "RA123456789",
    "01/01/2022",
    "Residential",
    "New Contract",
    "123456",
    "223fds922",
    "Opened",
    Icon({ type: "fa-solid fa-file", size: 16 }),
  ],
  [
    "12345678",
    "061111111",
    "RS12847182",
    "03/03/2023",
    "Business",
    "Contract Change",
    "87615",
    "992jksoq",
    "Closed",
    Icon({ type: "fa-solid fa-file", size: 16 }),
  ],
  [
    "98765432",
    "",
    "RS9876123",
    "12/12/2022",
    "Business",
    "Complaint",
    "92864",
    "123456",
    "Dismissed",
    Icon({ type: "fa-solid fa-file", size: 16 }),
  ],
];

import { Icon } from "../../modules/icon";
import { FiltersProps } from "./Filters.types";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { useFilters } from "./useFilters";
import { Filter } from "./Filter";
import "./Filters.style.css";
import React from "react";

export function Filters({ filters, onFilterChange }: FiltersProps) {
  const [open, setOpen] = React.useState(false);
  const toggleDropdown = () => setOpen(!open);
  const { selected, onAddFilter, onRemoveFilter, onSet } =
    useFilters(onFilterChange);
  return (
    <div className="d-flex flex-wrap">
      {selected.map((sf, i) => (
        <Filter
          key={sf.name}
          filter={sf}
          onRemove={onRemoveFilter}
          onFilterSet={onSet}
        />
      ))}
      {!selected.length || selected.length < filters.length ? (
        <DropdownButton
          onSelect={function () {
            return true;
          }}
          className="my-1"
          variant="dark"
          title={
            <>
              <Icon type="fa-solid fa-filter" />
              &nbsp; Add filter
            </>
          }
        >
          <div>
            {filters
              .filter((f) => !selected.find((s) => s.name === f.name))
              .map((f, i) => (
                <Dropdown.Item
                  className="dropdown-item dropdown-toggle"
                  key={i}
                  onClick={(event) => {
                    event.preventDefault();
                    onAddFilter(f);
                    toggleDropdown();
                  }}
                >
                  {f.name}
                </Dropdown.Item>
              ))}
          </div>
        </DropdownButton>
      ) : null}
    </div>
  );
}

import {
  SideNavBarProps,
  logoBigWidth,
  logoSmallWidth,
  logoBigMargin,
  logoSmallMargin,
  sideMenuItems,
} from "./SideNav.types";
import "./SideNav.style.css";
import assests from "../../config/assets";
import { SideNavMenuItem } from "./SideNavMenuItem";
import { Icon } from "../../modules/icon";
import { useSideNav } from "./useSideNav";
import { useUser } from "../../store/auth/AuthSelectors";

export function SideNavBar({ isOpen }: SideNavBarProps) {
  const { navigate, logOut, activeRoute } = useSideNav();
  const user = useUser();

  return (
    <div className={"side-nav-bar"}>
      <div
        className={"text-center"}
        style={{ marginBottom: isOpen ? logoBigMargin : logoSmallMargin }}
      >
        <img
          src={assests.images.logo}
          alt="A1 logo"
          width={isOpen ? logoBigWidth : logoSmallWidth}
        />
      </div>
      <div>
        {sideMenuItems.map((menuItem, index) => (
          <SideNavMenuItem
            key={index}
            menuItem={menuItem}
            isOpen={isOpen}
            activeRoute={activeRoute}
            navigate={navigate}
          />
        ))}
      </div>
      <div className="mt-auto mb-5">
        <div
          className={`side-nav-user
          ${isOpen ? "" : "justify-content-center"}`}
        >
          <div className={`side-nav-menu-item-icon${isOpen ? "" : " mx-0"}`}>
            <Icon type="fa-solid fa-user" size={18} />
          </div>
          <div
            className={`side-nav-menu-item-label${isOpen ? "" : " opacity-0"}`}
          >
            <span className="side-nav-menu-item-label-text">{user?.name}</span>
          </div>
        </div>

        <div
          className={`side-nav-user
          ${isOpen ? "" : "justify-content-center"}`}
          onClick={logOut}
        >
          <div className={`side-nav-menu-item-icon${isOpen ? "" : " mx-0"}`}>
            <Icon type={"fa-solid fa-right-from-bracket"} size={18} />
          </div>
          <div
            className={`side-nav-menu-item-label${isOpen ? "" : " opacity-0"}`}
          >
            <span className="side-nav-menu-item-label-text">Log out</span>
          </div>
        </div>
      </div>
    </div>
  );
}

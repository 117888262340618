import { useNavigate } from "react-router";
import { PageLayout } from "../../components/page-layout";
import { FileUpload } from "../../modules/file-upload";
import { screens } from "../Navigation.types";

export function UploadDocumentScreen() {
  const navigate = useNavigate();

  const onUpload = (files: File[]) => {
    if (!files || !files.length) return;
    navigate(screens.newDocument, { state: { file: files[0] } });
  };
  return (
    <PageLayout title="Upload Document">
      <FileUpload onUpload={onUpload} maxFiles={1} />
    </PageLayout>
  );
}

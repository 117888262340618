import React, { ChangeEvent } from "react";
import { useLocation, useNavigate } from "react-router";
import useBaseRequest from "../../api/BaseRequest";
import DocumentGateway from "../../api/gateways/DocumentGateway";
import { GenericForm } from "../../components/generic-form/GenericForm.types";
import { useToast } from "../../components/toast";
import { MetaDataForm } from "../../entities/IMetaData";
import { useWindowDimensions } from "../../modules/window";
import { useDocumentService } from "../../services/useDocumentService";
import { screens } from "../Navigation.types";

export function useNewDocument() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { success, danger } = useToast();
  let onTaskCreate = false;
  const {
    mapMetaDataRulesToForm,
    useGetDocumentTypes,
    useGetMetaDataForDocType,
  } = useDocumentService();
  const { height: windowHeight } = useWindowDimensions();
  const { documentTypes } = useGetDocumentTypes();
  const { getMetaDataForDocType, loading } = useGetMetaDataForDocType({
    onCompleted: (data) => {
      if (!data || !data.length) {
        setMetaDataForm(null);
        return;
      }
      const form = mapMetaDataRulesToForm(data);
      setMetaDataForm(form);
    },
  });

  const { execute: createDoc, loading: creating } = useBaseRequest(
    DocumentGateway.createDocument,
    {
      onCompleted: (data, payload) => {
        success("Document uploaded.");
        navigate(screens.documents + `${onTaskCreate ? `/${data.id}` : ""}`, {
          state: { isTaskCreating: onTaskCreate },
        });
      },
    }
  );

  const [docFile, setDocFile] = React.useState<{
    uri: string;
    type: string;
    name: string;
    file: File;
  } | null>(null);
  const [documentType, setDocumentType] = React.useState("");
  const [metaDataForm, setMetaDataForm] =
    React.useState<GenericForm<MetaDataForm> | null>(null);

  React.useEffect(() => {
    if (!state || !state.file) return;
    const file = state.file as File;
    setDocFile({
      uri: URL.createObjectURL(state.file),
      type: file.type,
      name: file.name || "Document file",
      file,
    });
  }, [state]);

  const onDocumentTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setDocumentType(value);
    if (!value) {
      setMetaDataForm(null);
      return;
    }
    getMetaDataForDocType({ code: value });
  };

  const onFileChange = (newFile: File) => {
    setDocFile({
      uri: URL.createObjectURL(newFile),
      type: newFile.type,
      name: newFile.name || "DocumentFile",
      file: newFile,
    });
  };

  const onCreateDoc = (mtdForm: MetaDataForm) => {
    if (!docFile) {
      danger("Document file required.");
      return;
    }
    createDoc({
      docTypeCode: documentType,
      metaData: mtdForm,
      file: docFile.file,
    });
  };

  const onCancel = () => {
    navigate(screens.uploadDocument);
  };

  const onTaskCreateHandler = () => {
    onTaskCreate = true;
  };
  return {
    docFile,
    documentType,
    documentTypes,
    onDocumentTypeChange,
    onFileChange,
    metaDataForm,
    loadingMetaDataForm: loading,
    onCreateDoc,
    creating,
    windowHeight,
    onCancel,
    onTaskCreateHandler,
  };
}

export interface IconProps {
  type: string;
  size?: number | string;
  onClick?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  color?: string;
  title?: string;
  id?: string;
  className?: string;
}

export const defaultIconSize: number = 14;

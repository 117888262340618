import { ButtonProps } from "./Button.types";
import { Loader } from "../../modules/loader";

export function Button({
  onClick,
  className,
  children,
  isLoading,
  loader,
  type,
  name,
  disabled,
}: ButtonProps) {
  return (
    <button
      onClick={onClick}
      className={className}
      type={type}
      name={name}
      disabled={disabled || isLoading}
    >
      {isLoading ? <Loader {...loader} /> : children}
    </button>
  );
}

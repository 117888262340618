import { useLocation } from "react-router";
import { PageLayout } from "../../components/page-layout";
import { SearchArchiveTable } from "./SearchArchiveTable";

export function SearchScreen() {
  const { state } = useLocation();
  return (
    <PageLayout
      title={`Search ${state.textSearch ? ` "${state.textSearch}"` : ""}`}
    >
      <div className="mb-3">
        <h5>Archive</h5>
        <SearchArchiveTable />
      </div>
      {/* todo(np): coming in v2 */}
      {/* <div className="mb-3">
        <h5>Tasks</h5>
        <span>...</span>
      </div> */}
    </PageLayout>
  );
}

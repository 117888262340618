export interface IMetaDataRule {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  required: boolean;
  editable: boolean;
  defaultValue: string;
  validation?: IValidationRule[];
  item: IMetaDataItem;
}

export enum MetaDataValidators {
  REQUIRED = "required",
  MIN_CHAR = "minChar",
  MAX_CHAR = "maxChar",
  EMAIL = "email",
  PASSWORD = "password",
  NUMBER = "number",
  NUMERIC_ONLY = "numericOnly",
  REGEX = "regex",
}

export interface IValidationRule {
  type: MetaDataValidators;
  params?: { [key: string]: string | number | boolean | Date };
}

export interface MetaDataForm {
  [key: string]: string | number | Date;
}

export interface IMetaDataItem {
  code: string;
  name: string;
  key: keyof MetaData;
  description?: string;
  dataType: MetaDataType;
  filterEnabled: boolean;
  allowedValues?: Array<{ key: string; value: string }>;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface MetaData {
  jmbg?: string;
  mb?: string;
  msisdn?: string;
  boxId?: string;
  documentType?: string;
  documentCategory?: string;
  signDate?: Date;
  documentNumber?: string;
  archiveType?: string;
}

export enum MetaDataType {
  TEXT = "string",
  NUMBER = "integer",
  BOOL = "boolean",
  DATE = "datetime",
  SELECT = "select",
}

import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../../components/button";
import { GenericForm } from "../../components/generic-form";
import { GenericFormValidator } from "../../components/generic-form/utility/GenericFormValidator";
import { Icon } from "../../modules/icon";
import { AddToBoxModalProps } from "./Documents.types";

export function AddToBoxModal({
  show,
  onCancel,
  onAdd,
  documentIds,
  adding,
}: AddToBoxModalProps) {
  const [enterBoxActive, setEnterBoxActive] = React.useState(true);
  const [boxId, setBoxId] = React.useState("");

  const submitBoxId = (payload: { boxId: string }) => {
    setBoxId(payload.boxId);
    setEnterBoxActive(false);
  };

  React.useEffect(() => {
    if (!show) {
      setBoxId("");
      setEnterBoxActive(true);
    }
  }, [show]);

  return (
    <Modal show={show} animation onHide={onCancel}>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          {!enterBoxActive ? (
            <>
              <Icon
                type="fa-solid fa-chevron-left"
                size={18}
                onClick={() => setEnterBoxActive(true)}
              />
              &nbsp;
            </>
          ) : null}
          Add to box
        </h5>
        <div onClick={onCancel}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        {enterBoxActive ? (
          <GenericForm
            form={{
              boxId: {
                type: "input",
                label: "Box ID",
                defaultValue: boxId,
                name: "boxId",
                validators: [GenericFormValidator.required()],
              },
            }}
            onSubmit={submitBoxId}
            submitControl={(submit) => (
              <Button
                type="button"
                className="btn btn-primary w-100"
                onClick={submit}
              >
                Save
              </Button>
            )}
          />
        ) : (
          `Are you sure you want to add ${documentIds.length} document(s) to Box #${boxId}?`
        )}
      </Modal.Body>
      {!enterBoxActive ? (
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onCancel}
          >
            Cancel
          </button>
          <Button
            type="button"
            className="btn btn-primary"
            onClick={() => onAdd(boxId)}
            isLoading={adding}
            loader={{ size: "s" }}
          >
            Add
          </Button>
        </Modal.Footer>
      ) : null}
    </Modal>
  );
}

import React from "react";
import { DrawerRef } from "./Drawer.types";

export const useDrawerRef = () => {
  return React.useRef<DrawerRef>({
    open: () => {},
    close: () => {},
    toggle: () => {},
  });
};

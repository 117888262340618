import { useRef } from "react";
import { CardsGridProps } from "./Card.types";
import useCard from "./useCard";

const CardGontainer = ({
  children,
  cardGap,
  cardMaxNumberPerRow,
  cardMinWidth,
  isInline = false,
}: CardsGridProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { cardWidth } = useCard(
    containerRef,
    cardGap,
    cardMaxNumberPerRow,
    cardMinWidth,
    5
  );
  return (
    <div
      ref={containerRef}
      className={`card-container d-flex ${
        isInline ? "flex-nowrap" : "flex-wrap"
      }`}
      style={{
        gap: `${cardGap}px`,
      }}
    >
      {children.map((item, i) => (
        <div
          key={i}
          style={{
            width: cardWidth,
            transition: "width 0.3s ease-out",
            minWidth: `${cardMinWidth}px`,
            flexShrink: 0,
          }}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export default CardGontainer;

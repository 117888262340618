import { Modal } from "react-bootstrap";
import { Button } from "../../components/button";

import { DeleteBasketModalProps } from "./Basket.types";

const DeleteBasketModal = ({
  isVisible,
  onDismiss,
  onDelete,
  deleting,
}: DeleteBasketModalProps) => {
  return (
    <Modal show={isVisible} animation onHide={onDismiss}>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          Delete Basket
        </h5>
        <div onClick={onDismiss}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this basket? This action is
        irreversible.
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" className="btn btn-secondary" onClick={onDismiss}>
          Cancel
        </Button>
        <Button
          isLoading={deleting}
          type="button"
          className="btn btn-danger"
          onClick={onDelete}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteBasketModal;

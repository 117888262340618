import React from "react";
import { RequestOptions } from "./Request.types";

export function useRequest<TData, TPayload>(
  request: (payload: TPayload) => Promise<TData>,
  options: RequestOptions<TData, TPayload> = {}
) {
  // REQUEST STATE
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<TData | null>(null);
  const [error, setError] = React.useState<any>(null);

  const {
    cleanup = () => {
      // cleanup
    },
    onCompleted = () => {
      console.log("Requested completed!");
    },
    onError = (error: any) => {
      console.log("Error occured ", error);
    },
    pollInterval,
    autoFetch,
    initialPayload,
  } = options;

  const intervalInstance = React.useRef<NodeJS.Timer | null>(null);
  const cleanupFn = () => {
    if (intervalInstance.current) clearInterval(intervalInstance.current);
    cleanup();
  };

  // cb for the request - memoized and won't recreate on rebuilds
  // NOTE(np): potentially not more efficient than a regular function
  const execute = React.useCallback(
    (payload: TPayload) => {
      const fetchFn = async () => {
        // onErrorFn and onCompletedFn init - has to be inside the cb
        const onErrorFn = (error: unknown) => {
          setError(error);
          onError(error);
        };

        const onCompletedFn = (data: TData, payload: TPayload) => {
          setData(data);
          onCompleted(data, payload);
        };

        // body of the request - executes the request and updates the request state
        try {
          setLoading(true);
          const response = await request(payload);
          onCompletedFn(response, payload);
        } catch (error) {
          onErrorFn(error);
        } finally {
          setLoading(false);
        }
      };
      fetchFn();

      // inteded for long polling
      if (pollInterval) {
        intervalInstance.current = setInterval(() => {
          fetchFn();
        }, pollInterval);
      }
    },
    [onCompleted, onError, pollInterval, request]
  );

  // Do cleanup on unmounting for logn polling
  // Execute request on component mount if autoFetch enabled
  React.useEffect(() => {
    if (autoFetch && !!initialPayload) {
      console.log("Init execution...");
      execute(initialPayload);
    }
    return () => cleanupFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, error, data, execute };
}

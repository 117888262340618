import { toast, ToastOptions } from "react-toastify";

export function useToast() {
  function success(text: string) {
    toast.success(text);
  }

  function danger(text: string) {
    toast.error(text);
  }

  function info(text: string) {
    toast.info(text);
  }

  function warning(text: string) {
    toast.warning(text);
  }

  function custom(text: string, options?: ToastOptions) {
    toast(text, options);
  }

  function dismissAll() {
    toast.dismiss();
  }
  return { success, danger, info, warning, custom, dismissAll };
}

const publicUrl = (window as any).public_url || "";

export const screens = {
  documents: "/documents",
  login: "/login",
  uploadDocument: "/upload-document",
  archive: "/archive",
  newDocument: "/new-document",
  search: "/search",
  baskets: "/baskets",
  basketTasks: "/baskets/:id",
  tasks: "/tasks/:id",
  root: publicUrl + "/",
};

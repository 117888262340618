import { Modal } from "react-bootstrap";
import { Button } from "../../components/button";
import { GenericForm } from "../../components/generic-form";

import { UpdateBasketModalProps } from "./Basket.types";

const UpdateBasketModal = ({
  isVisible,
  onDismiss,
  onSubmit,
  updating,
  updateBasketForm,
}: UpdateBasketModalProps) => {
  return (
    <Modal show={isVisible} animation onHide={onDismiss}>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          Update Basket
        </h5>
        <div onClick={onDismiss}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <GenericForm
          form={updateBasketForm}
          onSubmit={onSubmit}
          submitControl={(submit) => (
            <div className="flex-row">
              <Button
                isLoading={updating}
                loader={{ size: "s" }}
                className="btn btn-primary mt-3 w-100"
                type="submit"
              >
                Update
              </Button>
            </div>
          )}
        />
      </Modal.Body>
    </Modal>
  );
};

export default UpdateBasketModal;

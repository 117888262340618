import { Filter } from "../components/filters";
import { TableHeaders } from "../components/table";

export interface ITask {
  id: number;
  taskTypeCode: keyof typeof AccountingPeriods;
  createdAt: string;
  docTypeDoc: string;
  status: string;
  /*Add later  createdBy: string;
  assignee: string;*/
}

export const tasksTableHeaders: TableHeaders[] = [
  { name: "ID", key: "id", sortable: true },
  { name: "Accounting period", key: "taskTypeCode", sortable: true },
  /*Add later { name: "Created by", key: "createdBy" },
  { name: "Assigned to", key: "assignedBy" },*/
  { name: "Created date", key: "createdAt", sortable: true },
  { name: "Status", key: "status", sortable: true },
  { name: "Actions", key: "action" },
];

export const AccountingPeriods = {
  bus_c42: "C42",
  res_c17: "C17",
  res_c21: "C21",
  res_c25: "C25",
  res_c43: "C43",
  res_c45: "C45",
  res_c5: "C5",
};

export const TASK_SUCCESS_MESSAGES = {
  created: "Task successfully created!",
};
export interface CreateTaskDTO {
  documentId: number;
  taskTypeCode: keyof typeof AccountingPeriods | "";
}

export interface GetTasksByBasketIdDTO {
  basketId: number;
  filters: {
    textSearch?: string;
    skip?: number;
    limit?: number;
    myTasks?: boolean;
    sortBy?: string;
    order?: "asc" | "desc";
  };
}

export interface IGetTasksByBasketIdApi {
  count: number;
  tasks: ITask[];
}

export const tableFilters: Filter[] = [
  {
    type: "single-choice",
    name: "Show only mine tasks",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    buildQuery: (filter, values) => {
      return {
        name: "myTasks",
        type: "string",
        operator: values[0] === "yes" ? "ne" : "eq",
        value: null,
      };
    },
  },
];

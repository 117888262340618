export interface FiltersProps {
  filters: Filter[];
  onFilterChange: (queryFilters: QueryFilter[]) => any;
}

export interface FilterProps {
  filter: Filter;
  onRemove: (filter: Filter) => any;
  onFilterSet: (queryFilter: QueryFilter, filter: Filter) => any;
}

export interface Filter {
  type: FilterType;
  name: string;
  buildQuery: (
    filter: Filter,
    value: string[] | Date[] | number[]
  ) => QueryFilter;
  options?: Array<{ label: string; value: string | number }>;
}

export interface QueryFilter {
  name: string;
  value: any;
  operator: string;
  type: DataType;
}

export type FilterType = "date-range" | "single-choice";

export type DataType =
  | "string"
  | "date"
  | "number"
  | "stringArray"
  | "dateArray"
  | "numberArray";

export enum DateRangeTypeValues {
  TODAY = "Today",
  THIS_WEEK = "This week",
  THIS_MONTH = "This month",
  CUSTOM = "Custom",
}

import { Button } from "../../components/button";
import { FilePreview } from "../../components/file-preview";
import { GenericForm } from "../../components/generic-form";
import { Column, Row } from "../../components/grid";
import { PageLayout } from "../../components/page-layout";
import { Icon } from "../../modules/icon";
import { Loader } from "../../modules/loader";
import { useNewDocument } from "./useNewDocument";

export function NewDocumentScreen() {
  const {
    docFile,
    documentType,
    documentTypes,
    onDocumentTypeChange,
    metaDataForm,
    loadingMetaDataForm,
    onCreateDoc,
    creating,
    windowHeight,
    onFileChange,
    onCancel,
    onTaskCreateHandler,
  } = useNewDocument();

  return (
    <PageLayout
      title="New Document"
      titleRight={
        <div className="d-flex flex-row align-items-center justify-content-end m-3 mt-4">
          <Button className="btn btn-danger" onClick={onCancel}>
            <Icon type="fa-solid fa-xmark" size={16} />
            &nbsp; Cancel
          </Button>
        </div>
      }
    >
      <Row>
        <Column>
          {docFile ? (
            <div className="d-flex flex-column align-items-center">
              <FilePreview
                enableEditing
                type={docFile.type}
                url={docFile.uri}
                name={docFile.name}
                width={(windowHeight - 250) / 1.4}
                height={windowHeight - 250}
                onFileChosen={onFileChange}
              />
            </div>
          ) : null}
        </Column>
        <Column>
          <div
            className="d-flex flex-column"
            style={{ maxWidth: (windowHeight - 250) / 1.4 }}
          >
            <div className="form-group">
              <label>Document type</label>
              <select
                className="form-select"
                value={documentType}
                onChange={onDocumentTypeChange}
              >
                <option value=""></option>
                {documentTypes.map((docType) => (
                  <option key={docType.code} value={docType.code}>
                    {docType.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-4">
              {loadingMetaDataForm ? (
                <Loader size="l" />
              ) : metaDataForm ? (
                <div>
                  <h5>Document data</h5>
                  <GenericForm
                    className="mt-3"
                    form={metaDataForm}
                    onSubmit={onCreateDoc}
                    submitFormOnEnter={false}
                    submitControl={(submit) => (
                      <>
                        <Button
                          className="btn btn-primary w-100 mt-3"
                          type="button"
                          onClick={submit}
                          isLoading={creating}
                          disabled={creating}
                        >
                          Upload
                        </Button>
                        <Button
                          className="btn btn-secondary w-100 mt-3"
                          type="button"
                          onClick={() => {
                            onTaskCreateHandler();
                            submit();
                          }}
                          isLoading={creating}
                          disabled={creating}
                        >
                          Upload and Create Task
                        </Button>
                      </>
                    )}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </Column>
      </Row>
    </PageLayout>
  );
}

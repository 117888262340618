import { Drawer, useDrawerRef } from "../../modules/drawer";
import { useWindowDimensions } from "../../modules/window";
import { breakPoints } from "../grid";
import { SideNavBar } from "./SideNavBar";
import { SideNavHeader } from "./SideNavHeader";
import "./SideNav.style.css";
import { Outlet } from "react-router-dom";

export function SideNav() {
  const { width } = useWindowDimensions();
  const drawerRef = useDrawerRef();

  const toggle = () => {
    drawerRef.current?.toggle();
  };

  return (
    <Drawer
      initialVisibility={width <= breakPoints.m ? "closed" : "opened"}
      type={width <= breakPoints.m ? "overlay" : "side"}
      drawerConent={(props) => <SideNavBar {...props} />}
      drawerRef={drawerRef}
    >
      <SideNavHeader toggle={toggle} />
      <Outlet />
    </Drawer>
  );
}

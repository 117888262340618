import { Icon } from "../../modules/icon";
import { SideNavMenuItemProps } from "./SideNav.types";

export function SideNavMenuItem({
  menuItem,
  activeRoute,
  isOpen,
  navigate,
}: SideNavMenuItemProps) {
  const navigateTo = () => navigate(menuItem.path);
  return (
    <div
      className={`side-nav-menu-item
      ${isOpen ? "" : "justify-content-center"}
      ${menuItem.value === activeRoute ? " active" : ""}
      `}
      onClick={navigateTo}
    >
      <div className={`side-nav-menu-item-icon${isOpen ? "" : " mx-0"}`}>
        <Icon type={menuItem.icon} size={18} />
      </div>
      <div className={`side-nav-menu-item-label${isOpen ? "" : " opacity-0"}`}>
        <span className="side-nav-menu-item-label-text">{menuItem.label}</span>
      </div>
    </div>
  );
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDocumentType } from "../../entities/IDocument";

interface DocumentsState {
  documentTypes: IDocumentType[];
}

const initialState: DocumentsState = {
  documentTypes: [],
};

export const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    setDocumentTypes: (
      state: DocumentsState,
      action: PayloadAction<IDocumentType[]>
    ) => {
      state.documentTypes = action.payload;
    },
  },
});

export const documentsActions = documentsSlice.actions;

export default documentsSlice.reducer;

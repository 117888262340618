import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./auth/AuthReducer";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import persistStore from "redux-persist/es/persistStore";
import DocumentsReducer from "./documents/DocumentReducer";

// store
export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    documents: DocumentsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
});

// store state
export type RootState = ReturnType<typeof store.getState>;

// dispatch
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

// selector
export type AppSelector<S> = (state: RootState) => S;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

//persistor
export const persistor = persistStore(store);

import React from "react";
import { Button } from "../../components/button";
import { Filters } from "../../components/filters";
import { Column, Row } from "../../components/grid";
import { PageLayout } from "../../components/page-layout";
import { SearchInput } from "../../components/search-input";
import { Table } from "../../components/table";
import { Icon } from "../../modules/icon";
import { AddToBoxModal } from "./AddToBoxModal";
import { tableFilters } from "./Documents.types";
import { useDocuments } from "./useDocuments";

export function DocumentsScreen() {
  const {
    docTableData,
    onSearchSubmit,
    user,
    count,
    onDocumentPerPageChange,
    setSelectedDocuments,
    selectedDocuments,
    onPageChange,
    showBoxModal,
    onAddBoxId,
    closeBoxModal,
    openBoxModal,
    updating,
    documentTableHeaders,
    onFilterChange,
    loading,
    onSort,
    onRowPress,
  } = useDocuments();

  return (
    <PageLayout
      title={`Documents - ${
        user?.dealerCode === user?.id.toString()
          ? "Uploaded by you"
          : `POS/Dealer #${user?.dealerCode}`
      }`}
    >
      <Row>
        <Column>
          {selectedDocuments && selectedDocuments.length ? (
            <Button className="btn btn-secondary" onClick={openBoxModal}>
              <Icon type="fa-solid fa-box" />
              &nbsp; Add to box ({selectedDocuments.length})
            </Button>
          ) : null}
          <AddToBoxModal
            show={showBoxModal}
            documentIds={selectedDocuments}
            onAdd={onAddBoxId}
            onCancel={closeBoxModal}
            adding={updating}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <div className="mt-4 mb-1">
            <Filters filters={tableFilters} onFilterChange={onFilterChange} />
          </div>
        </Column>
        <Column>
          <div className="mt-4 mb-1">
            <div className="d-flex flex-row my-1">
              <SearchInput
                placeHolder="Search documents..."
                className="ml-auto"
                onSearchSubmit={onSearchSubmit}
              />
            </div>
          </div>
        </Column>
      </Row>
      <Row>
        <Column>
          <div className="my-2 w-100">
            <Table
              loading={loading}
              headers={documentTableHeaders}
              data={docTableData}
              pagination={{
                totalItems: count,
                onItemsPerPageChange: onDocumentPerPageChange,
                onPageChange,
              }}
              selection={{
                onSelectionChanged: setSelectedDocuments,
                selected: selectedDocuments,
              }}
              sort={{ onSort }}
              onRowPress={onRowPress}
            />
          </div>
        </Column>
      </Row>
    </PageLayout>
  );
}

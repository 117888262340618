import { FilterProps } from "./Filters.types";
import { Button } from "../button";
import { Icon } from "../../modules/icon";
import { DropdownButton, ButtonGroup, Dropdown } from "react-bootstrap";
import React, { ChangeEvent } from "react";
import "./Filters.style.css";

export function SingleChoiceFilter({
  filter,
  onRemove,
  onFilterSet,
}: FilterProps) {
  const [value, setValue] = React.useState(null);
  const [appliedValue, setAppliedValue] = React.useState(null);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value as any);
  };

  const appplyFilter = () => {
    if (!value) return;
    setAppliedValue(value);
    onFilterSet(filter.buildQuery(filter, [value]), filter);
  };

  return (
    <div className="mr-3 my-1">
      <div className="btn-group">
        <DropdownButton
          as={ButtonGroup}
          variant="outline-dark"
          onToggle={(show) => {
            if (show) {
              setValue(appliedValue);
            }
          }}
          onSelect={() => true}
          title={
            <>
              {filter.name}
              {appliedValue
                ? `: ${
                    filter.options?.find((o) => o.value === appliedValue)?.label
                  }`
                : null}
              &nbsp;
            </>
          }
        >
          <div>
            <div className="px-2 pt-2">
              {filter.options?.map((option, key) => (
                <div className="form-check" key={key}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name={filter.name}
                    value={option.value}
                    onChange={onChange}
                    checked={value === option.value}
                  />
                  <label className="form-check-label">{option.label}</label>
                </div>
              ))}
              <div className="apply">
                <Dropdown.Item
                  className="btn btn-primary mt-3"
                  onClick={appplyFilter}
                >
                  Apply
                </Dropdown.Item>
              </div>
            </div>
          </div>
        </DropdownButton>
        <Button
          className="btn btn-outline-dark"
          onClick={() => onRemove(filter)}
        >
          <Icon type="fa-solid fa-xmark" size={16} />
        </Button>
      </div>
    </div>
  );
}

import { Button } from "../../components/button";
import { GenericForm } from "../../components/generic-form";
import { DocumentDataUpdateProps } from "./Document.types";

export function DocumentDataUpdate({
  docType,
  metaDataForm,
  onUpdate,
  updating = false,
}: DocumentDataUpdateProps) {
  return (
    <div className="d-flex flex-column">
      <div className="form-group">
        <label>Document type</label>
        <input
          className="form-control bg-light cursor-text"
          value={docType.title}
          disabled
        />
      </div>
      <div className="mt-4">
        {metaDataForm ? (
          <div>
            <h5>Document data</h5>
            <GenericForm
              className="mt-3"
              form={metaDataForm}
              onSubmit={onUpdate}
              submitControl={(submit) => (
                <Button
                  className="btn btn-primary w-100 mt-3"
                  type="button"
                  onClick={submit}
                  isLoading={updating}
                  disabled={updating}
                >
                  Save
                </Button>
              )}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

import {
  CreateTaskDTO,
  GetTasksByBasketIdDTO,
  IGetTasksByBasketIdApi,
} from "../../entities/ITask";
import BaseInstance from "../BaseInstance";

const createFilterQueryWithObjectKeys = (filters: any) => {
  let filtersQuery = "";
  if (!!filters) {
    filtersQuery = "?";
    Object.keys(filters).forEach((key, i) => {
      filtersQuery += `${i ? "&" : ""}${key}=${filters[key]}`;
    });
  }
  return filtersQuery;
}; // MAKE THIS A UTIL FUNCTION?

const TaskGateway = {
  createTask: async (payload: CreateTaskDTO) => {
    return BaseInstance.post("/tasks", payload);
  },
  getTasksByBasketId: async (
    payload: GetTasksByBasketIdDTO
  ): Promise<IGetTasksByBasketIdApi> => {
    return BaseInstance.get(
      `/baskets/${payload.basketId}/tasks${createFilterQueryWithObjectKeys(
        payload.filters
      )}`
    );
  },
};

export default TaskGateway;

import React from "react";
import { Filter, QueryFilter } from "./Filters.types";

export function useFilters(
  onFilterChange: (queryFilters: QueryFilter[]) => any
) {
  const [selected, setSelected] = React.useState<Filter[]>([]);
  const [queryFilters, setQueryFilters] = React.useState<
    { name: string; query: QueryFilter }[]
  >([]);

  const onAddFilter = (filter: Filter) => {
    if (!selected.find((s) => s.name === filter.name)) {
      setSelected([...selected, filter]);
    }
  };

  const onRemoveFilter = (filter: Filter) => {
    setSelected(selected.filter((sl) => sl.name !== filter.name));
    if (queryFilters.find((f) => f.name === filter.name)) {
      const newQuerFilters = queryFilters.filter(
        (qf) => qf.name !== filter.name
      );
      setQueryFilters(newQuerFilters);
      onFilterChange(newQuerFilters.map((nqf) => nqf.query));
    }
  };

  const onSet = (queryFilter: QueryFilter, filter: Filter) => {
    let newFilters = [];
    if (!!queryFilters.find((qf) => qf.query.name === queryFilter.name)) {
      newFilters = queryFilters
        .filter((qf) => qf.name !== queryFilter.name)
        .concat([{ name: filter.name, query: queryFilter }]);
    } else {
      newFilters = queryFilters.concat([
        { name: filter.name, query: queryFilter },
      ]);
    }
    setQueryFilters(newFilters);
    onFilterChange(newFilters.map((nf) => nf.query));
  };

  return { selected, onAddFilter, onRemoveFilter, onSet };
}

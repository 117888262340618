import { LoaderProps } from "./Loader.types";
import "./Loader.style.css";
import { useLoader } from "./useLoader";

export function Loader({ size, color }: LoaderProps) {
  const style = useLoader({ size, color });

  return (
    <div
      className="loader"
      style={{
        height: style.heightWidth,
        width: style.heightWidth,
        display: "inline-block",
        borderWidth: style.borderWidth,
        borderStyle: "solid",
        borderRightColor: "transparent",
        borderTopColor: style.color,
        borderLeftColor: style.color,
        borderBottomColor: style.color,
        borderRadius: "50%",
      }}
    />
  );
}

import { useEffect, useState } from "react";
import { useDrawerContext } from "../../modules/drawer";

const useCard = (
  containerRef: any,
  cardGap: number,
  cardMaxNumberPerRow: number,
  cardMinWidth: number,
  paddingX: number
) => {
  const useGetContainerWidth = (containerRef: any) => {
    const [width, setWidth] = useState(0);
    const { isOpen } = useDrawerContext();
    useEffect(() => {
      const getWidth = () => containerRef.current.offsetWidth;

      const handleResize = () => {
        setWidth(getWidth());
      };

      if (containerRef.current) {
        setWidth(getWidth());
      }

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [containerRef]);

    useEffect(() => {
      const getWidth = () => containerRef.current.offsetWidth;
      if (containerRef.current)
        setTimeout(() => {
          setWidth(getWidth());
        }, 400);
    }, [isOpen, containerRef]);

    return width;
  };

  const calculateCardWidth = (
    width: number,
    maxCardNumber: number,
    minCardWidth: number,
    cardGap: number,
    paddingX: number
  ) => {
    let widthInPx = width - (maxCardNumber - 1) * cardGap - paddingX;
    let cardNumber = maxCardNumber;
    while (
      (width - (cardNumber - 1) * cardGap - paddingX) / cardNumber <
      minCardWidth
    ) {
      widthInPx = width - (cardNumber - 2) * cardGap - paddingX;
      cardNumber--;
    }
    return `${widthInPx / cardNumber}px`;
  };

  const containerWidth = useGetContainerWidth(containerRef);
  const cardWidth = calculateCardWidth(
    containerWidth,
    cardMaxNumberPerRow,
    cardMinWidth,
    cardGap,
    paddingX
  );

  return {
    containerWidth,
    cardWidth,
  };
};

export default useCard;

import { SideNavHeaderProps } from "./SideNav.types";
import "./SideNav.style.css";
import { Icon } from "../../modules/icon";
import { useNavigate } from "react-router";
import { screens } from "../../screens/Navigation.types";
import "./SideNav.style.css";
import { SearchInput } from "../search-input";
import { Button } from "../button";

export function SideNavHeader({ toggle }: SideNavHeaderProps) {
  const navigate = useNavigate();
  const goToUpload = () => {
    navigate(screens.uploadDocument);
  };
  const onSearch = (text: string) => {
    if (!text) return;
    navigate(screens.search, { state: { textSearch: text } });
  };
  return (
    <div className="side-nav-header">
      <div>
        <Icon type="fa fa-bars" size={20} onClick={toggle} />
      </div>
      <div className="ml-auto mr-4">
        {/* <SearchInput onSearchSubmit={onSearch} /> */}
      </div>
      <div>
        <Button className="btn btn-primary" onClick={goToUpload}>
          <Icon type="fa fa-upload" />
          &nbsp; Upload document
        </Button>
      </div>
    </div>
  );
}

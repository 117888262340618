import "./Card.style.css";
import { CardProps } from "./Card.types";

const Card = ({ children, className, onClick }: CardProps) => (
  <div
    onClick={onClick}
    className={`p-2 flex-column card-default ${className}`}
  >
    {children}
  </div>
);

export default Card;

import React from "react";
import {
  GenericForm,
  GenericFormState,
  UseGenericFormProps,
} from "./GenericForm.types";
import { GenericFormValidator } from "./utility/GenericFormValidator";

export function useGenericForm<T>({
  onSubmit,
  entity,
  form,
  formRef,
  resetFormOnSubmit,
  submitFormOnEnter,
}: UseGenericFormProps<T>) {
  const onFormSubmit = (event: any) => {
    event.preventDefault();
    if (!submitFormOnEnter) return;
    submit();
  };

  entity = entity || ({} as T);
  const onSubmitCb = onSubmit
    ? onSubmit
    : () => {
        return;
      };
  const [formState, setFormState] = React.useState<GenericFormState<T>>(
    buildState<T>(form, entity)
  );

  React.useEffect(() => {
    if (formRef) {
      formRef.current.setState = (state) => {
        setFormState(buildState(form, state));
      };
    }
  }, [formRef, form]);

  function onValueChange<PValue>(key: keyof T, value: PValue) {
    if (!formState[key]) return;

    const newKeyState = {
      value,
      error: formState[key]?.error || null,
    };

    setFormState({ ...formState, [key]: newKeyState });
  }

  function submit() {
    const { validatedState, hasErrors } = validateForm(form, formState);
    setFormState(validatedState);
    if (hasErrors) {
      return;
    }
    const payload: T = populatePayload(form, formState);
    onSubmitCb(payload);

    if (resetFormOnSubmit) {
      setFormState(cleanState(form));
    }
  }

  return { onFormSubmit, submit, onValueChange, formState };
}

// helper func

function validateForm<FType>(
  F: GenericForm<FType>,
  state: GenericFormState<FType>
): {
  hasErrors: boolean;
  validatedState: GenericFormState<FType>;
} {
  let hasErrors = false;
  const validatedState: GenericFormState<FType> = {} as GenericFormState<FType>;
  Object.keys(F).forEach((key) => {
    const k = key as keyof FType;
    validatedState[k] = {
      value: state[k].value,
      error: state[k].error,
    };
    const control = F[k];
    if (!control.validators) {
      return;
    }
    const validator = new GenericFormValidator(
      state[k].value as any,
      control.label || key
    );
    const status = validator.validate(control.validators);
    validatedState[k].error = status.error || null;
    if (!status.valid) {
      hasErrors = true;
    }
  });
  return { hasErrors, validatedState };
}

function populatePayload<FType>(
  F: GenericForm<FType>,
  FS: GenericFormState<FType>
) {
  const fPayload: FType = {} as FType;
  if (!F) {
    return fPayload;
  }
  Object.keys(FS).forEach((key) => {
    const k = key as keyof FType;
    const controlFormState = FS[k];
    let v = controlFormState.value;
    if (typeof v === "string") {
      v = v.trim() as any;
    }
    fPayload[k] = v;
  });
  return fPayload;
}

function cleanState<FType>(F: GenericForm<FType>): GenericFormState<FType> {
  const state: GenericFormState<FType> = {} as GenericFormState<FType>;
  Object.keys(F).forEach((key) => {
    const k = key as keyof FType;
    state[k] = {
      value: F[k].defaultValue,
      error: null,
    };
  });
  return state;
}

function buildState<FType>(
  F: GenericForm<FType>,
  entity: FType
): GenericFormState<FType> {
  entity = entity || ({} as FType);
  const state: GenericFormState<FType> = {} as GenericFormState<FType>;
  Object.keys(F).forEach((key) => {
    const k = key as keyof FType;
    state[k] = {
      value: typeof entity[k] != "undefined" ? entity[k] : F[k].defaultValue,
      error: null,
    };
  });
  return state;
}

import { GenericForm } from "../../../components/generic-form/GenericForm.types";
import { GenericFormValidator } from "../../../components/generic-form/utility/GenericFormValidator";
import { AccountingPeriods } from "../../../entities/ITask";
import { CreateTaskDTO } from "../../../entities/ITask";

export const createTaskForm: GenericForm<CreateTaskDTO> = {
  taskTypeCode: {
    type: "select",
    name: "taskTypeCode",
    label: "Accounting period",
    defaultValue: "",
    options: [
      ...Object.keys(AccountingPeriods).map((key) => ({
        value: key,
        label: AccountingPeriods[key as keyof typeof AccountingPeriods],
      })),
      { label: "Select an accounting period", value: "" },
    ],
    validators: [
      GenericFormValidator.required({
        error: "Select an accounting period.",
      }),
    ],
  },
  documentId: {
    type: "hidden",
    visibleIf: () => false,
    name: "documentId",
    label: "Task is assignable",
    defaultValue: -1,
    validators: [],
  },
};

export interface CreateTaskModalProps {
  onDismiss: () => void;
  onSubmit: (data: CreateTaskDTO) => void;
  creating: boolean;
  isVisible: boolean;
  createTaskForm: GenericForm<CreateTaskDTO>;
}

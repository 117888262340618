import React from "react";

export const breakPoints = {
  // more or equal to
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
  xxl: 1400,
};

export interface ContainerProps extends React.PropsWithChildren {
  fluid?: boolean;
}

export interface RowProps extends React.PropsWithChildren {}

export interface ColumnProps extends React.PropsWithChildren {
  className?: string;
}

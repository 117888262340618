import { Column, Container, Row } from "../grid";
import { PageLayoutProps } from "./PageLayout.types";

export function PageLayout({
  title,
  children,
  fullScreen = true,
  titleRight,
}: PageLayoutProps) {
  return (
    <div className="w100">
      <Container fluid={fullScreen}>
        <Row>
          <Column className="p-0">
            {title ? <h3 className="m-4 mb-0 mt-5">{title}</h3> : null}
          </Column>
          {titleRight ? <Column>{titleRight}</Column> : null}
        </Row>
        <Row>
          <Column className="col p-0 p-4 pt-4">{children}</Column>
        </Row>
      </Container>
    </div>
  );
}

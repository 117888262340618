import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PersistConfig, persistReducer } from "redux-persist";
import { IUser } from "../../entities/IUser";
import storage from "redux-persist/lib/storage";

interface AuthState {
  user: IUser | null;
}

const initialState: AuthState = {
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state: AuthState, action: PayloadAction<IUser | null>) => {
      state.user = action.payload;
    },
  },
});

const persistConfig: PersistConfig<AuthState> = {
  key: "auth",
  storage,
};

export const authActions = authSlice.actions;

export default persistReducer(persistConfig, authSlice.reducer);

import { IEnvironment } from "./environment";

export const prodEnv: IEnvironment = {
  env: "production",
  api: {
    protocol: `${window.location.protocol.replace(":", "")}`,
    host:
      window.location.host === "partnerconnect.a1.rs"
        ? "partnerconnect.a1.rs/cvpn/http/aurora-backoffice.apps.ocpprod1.topnet.rs/api"
        : "aurora-api.apps.ocpprod1.topnet.rs",
  },
};

import { FilterProps } from "./Filters.types";
import { DateRangeFilter } from "./DateRangeFilter";
import { SingleChoiceFilter } from "./SingleChoiceFilter";

export function Filter(props: FilterProps) {
  switch (props.filter.type) {
    case "date-range":
      return <DateRangeFilter {...props} />;
    case "single-choice":
      return <SingleChoiceFilter {...props} />;
    default:
      return null;
  }
}

import axios, { AxiosError } from "axios";
import environment from "../config/environment";
import { store } from "../store";

const BaseInstance = axios.create({
  baseURL: `${environment.api.protocol}://${environment.api.host}${
    environment.api.port ? ":" + environment.api.port : ""
  }/${environment.api.version ? environment.api.version + "/" : ""}`,
});

BaseInstance.interceptors.request.use(
  (config) => {
    config = config || {};
    const token = store.getState().auth.user?.token;
    config.headers["Authorization"] = `Bearer ${token || ""}`;
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error?.message ? error : defaultRequestError);
  }
);

const defaultRequestError = { statusCode: 500, message: "Server error" };

BaseInstance.interceptors.response.use(
  (config) => Promise.resolve(config.data),
  (error: AxiosError) => {
    return Promise.reject(error?.response?.data || defaultResponseError);
  }
);

const defaultResponseError = { statusCode: 500, message: "Server error" };

export default BaseInstance;

import { NavigateFunction } from "react-router";
import { DrawerContentProps } from "../../modules/drawer";
import { screens } from "../../screens/Navigation.types";

export interface SideNavBarProps extends DrawerContentProps {}

export interface SideNavHeaderProps {
  toggle: () => void;
}

export interface SideNavMenuItemProps {
  menuItem: MenuItem;
  activeRoute: string;
  isOpen: boolean;
  navigate: NavigateFunction;
}

export const logoSmallWidth = 55;
export const logoBigWidth = 100;

export const logoSmallMargin = 112;
export const logoBigMargin = 70;

export interface MenuItem {
  icon: string;
  label: string;
  value: string;
  path: string;
}

export const sideMenuItems: MenuItem[] = [
  {
    icon: "fa-regular fa-folder-open",
    label: "Documents",
    value: "documents",
    path: screens.documents,
  },
  {
    icon: "fa-solid fa-box-archive",
    label: "Archive",
    value: "archive",
    path: screens.archive,
  },
  {
    icon: "fa-solid fa-tasks",
    label: "Baskets",
    value: "baskets",
    path: screens.baskets,
  },
];

import { IUser } from "../../entities/IUser";
import BaseInstance from "../BaseInstance";

const AuthGateway = {
  login: async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }): Promise<IUser> => {
    return BaseInstance.post("/token", { username, password });
  },
};

export default AuthGateway;

const publicUrl = (window as any).public_url || "";

const assests = {
  images: {
    logo: publicUrl + "/images/logo.png",
    reupload: publicUrl + "/images/reupload.png",
  },
};

export default assests;

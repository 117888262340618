import { defaultIconSize } from ".";
import "./Icon.style.css";
import { IconProps } from "./Icon.types";

export function Icon({
  type,
  size,
  onClick,
  color,
  title,
  id,
  className,
}: IconProps) {
  return (
    <i
      id={id}
      data-bs-toggle="tooltip"
      data-bs-placement="bottom"
      className={`${type}${className ? " " + className : ""}`}
      onClick={onClick}
      title={title}
      style={{
        fontSize: size || defaultIconSize,
        cursor: !!onClick ? "pointer" : undefined,
        color,
      }}
    />
  );
}

import { CheckBoxProps } from "./CheckBox.types";
import { useCheckBox } from "./useCheckBox";

export function CheckBox({
  checked = false,
  intermediate = false,
  onChange,
  className,
}: CheckBoxProps) {
  const { checkBoxRef } = useCheckBox(intermediate);
  return (
    <input
      className={className}
      type="checkbox"
      checked={checked}
      ref={checkBoxRef}
      onChange={onChange}
    />
  );
}

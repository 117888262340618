import "./BasketCard.style.css";
import { BasketCardInfoProps } from "../Basket.types";

const BasketCardInfo = ({ name, numberOfItems }: BasketCardInfoProps) => (
  <>
    <span className="basketcard-info-item-counter">
      {numberOfItems ? (
        <>
          <span className="fw-bold" style={{ fontSize: "14px" }}>
            {numberOfItems}
          </span>
          items
        </>
      ) : (
        "Empty"
      )}
    </span>
    <span
      className="basketcard-info-name fw-bold"
      data-bs-toggle={name.length > 34 ? "tooltip" : undefined}
      data-bs-placement={name.length > 34 ? "bottom" : undefined}
      title={name.length > 34 ? name : undefined}
    >
      {name.length > 34 ? name.slice(0, 34) + "..." : name}
    </span>
  </>
);

export default BasketCardInfo;

import { devEnv } from "./development";
import { APP_ENV_TYPES, IEnvironment } from "./environment";
import { prodEnv } from "./production";
import { stagingEnv } from "./staging";
import { testEnv } from "./test";

const getEnv = (): IEnvironment => {
  const env: APP_ENV_TYPES = (window as any)._env_.APP_ENV;

  switch (env) {
    case "production":
      return prodEnv;
    case "staging":
      return stagingEnv;
    case "test":
      return testEnv;
    default:
      return devEnv;
  }
};

export default getEnv();

import moment from "moment";
import { DocumentDataPreviewProps } from "./Document.types";

export function DocumentDataPreview({
  docType,
  metaDataForm,
}: DocumentDataPreviewProps) {
  return (
    <div className="d-flex flex-column">
      <div className="form-group">
        <label>Document type</label>
        <input
          className="form-control bg-light cursor-text"
          value={docType.title}
          disabled
        />
      </div>
      <div className="mt-4">
        {metaDataForm ? (
          <div>
            <h5>Document data</h5>
            <div className="mt-3">
              {Object.keys(metaDataForm).map((key, index) => {
                const item = metaDataForm[key];
                return (
                  <div className="form-group" key={index}>
                    <label>{item.label}</label>
                    <input
                      className="form-control bg-light cursor-text"
                      disabled
                      value={
                        item.type === "date" && item.defaultValue
                          ? moment(item.defaultValue).format("DD.MM.YYYY")
                          : item.defaultValue.toString()
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

import React from "react";

export function useCheckBox(intermediate: boolean) {
  const checkBoxRef = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    if (!checkBoxRef.current) return;
    checkBoxRef.current.indeterminate = intermediate;
  }, [checkBoxRef, intermediate]);

  return { checkBoxRef };
}

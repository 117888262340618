import { FilterProps } from "./Filters.types";
import { Button } from "../button";
import { Icon } from "../../modules/icon";
import { ButtonGroup, DropdownButton, Dropdown } from "react-bootstrap";
import React, { ChangeEvent } from "react";
import moment from "moment";
import { DateRangeTypeValues } from ".";

export function DateRangeFilter({
  filter,
  onRemove,
  onFilterSet,
}: FilterProps) {
  const [value, setValue] = React.useState(null);
  const [appliedValue, setAppliedValue] = React.useState(null);
  const [fromDate, setFromDate] = React.useState<Date>(
    moment().subtract(3, "days").startOf("day").toDate()
  );
  const [toDate, setToDate] = React.useState<Date>(new Date());

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as any;
    setValue(value);
    const to = moment().toDate();
    if (value === DateRangeTypeValues.TODAY) {
      setFromDate(moment().startOf("day").toDate());
      setToDate(to);
    }
    if (value === DateRangeTypeValues.THIS_WEEK) {
      setFromDate(moment().startOf("week").toDate());
      setToDate(to);
    }
    if (value === DateRangeTypeValues.THIS_MONTH) {
      setFromDate(moment().startOf("month").toDate());
      setToDate(to);
    }
  };

  const appplyFilter = () => {
    if (!value) return;
    setAppliedValue(value);
    onFilterSet(filter.buildQuery(filter, [fromDate, toDate]), filter);
  };

  return (
    <div className="mr-3 my-1">
      <div className="btn-group">
        <DropdownButton
          as={ButtonGroup}
          variant="outline-dark"
          onToggle={(show) => {
            if (show) {
              setValue(appliedValue);
            }
          }}
          title={
            <>
              {filter.name}
              {appliedValue
                ? appliedValue !== DateRangeTypeValues.CUSTOM
                  ? `: ${appliedValue}`
                  : `: ${moment(fromDate).format("DD.MM.YYYY")} - ${moment(
                      toDate
                    ).format("DD.MM.YYYY")}`
                : null}
            </>
          }
        >
          <div>
            <div className="px-2 pt-2">
              {Object.values(DateRangeTypeValues).map((dtValue) => {
                return (
                  <div className="form-check" key={dtValue}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name={filter.name}
                      value={dtValue}
                      onChange={onChange}
                      checked={value === dtValue}
                    />
                    <label className="form-check-label">{dtValue}</label>
                  </div>
                );
              })}
              {value === DateRangeTypeValues.CUSTOM ? (
                <div className="pl-2">
                  <div className="form-group">
                    <label>From</label>
                    <input
                      className="form-control"
                      type="date"
                      value={moment(fromDate).format("YYYY-MM-DD")}
                      onChange={(event) => {
                        const selectedDate = new Date(event.target.value);
                        setFromDate(selectedDate);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label>To</label>
                    <input
                      className="form-control"
                      type="date"
                      value={moment(toDate).format("YYYY-MM-DD")}
                      onChange={(event) => {
                        const selectedDate = new Date(event.target.value);
                        setToDate(selectedDate);
                      }}
                    />
                  </div>
                </div>
              ) : null}
              <div className="apply">
                <Dropdown.Item
                  className="btn btn-primary mt-3"
                  onClick={appplyFilter}
                >
                  Apply
                </Dropdown.Item>
              </div>
            </div>
          </div>
        </DropdownButton>
        <Button
          className="btn btn-outline-dark"
          onClick={() => onRemove(filter)}
        >
          <Icon type="fa-solid fa-xmark" size={16} />
        </Button>
      </div>
    </div>
  );
}

import { Icon } from "../../modules/icon";
import React from "react";
import { useNavigate } from "react-router-dom";
interface IHeader {
  title: string;
  actions?: React.ReactNode;
  backBtn?: boolean;
  size?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

const Header = (props: IHeader) => {
  const { title, actions, size = "h5" } = props;
  const navigate = useNavigate();
  return (
    <div
      className={`flex-row align-items-end justify-content-between width-100`}
    >
      <div className="flex-row gap-16">
        {props.backBtn ? (
          <Icon
            onClick={() => {
              navigate(-1);
            }}
            type={"fa-solid fa-chevron-left"}
          />
        ) : null}
        {size === "h1" ? (
          <h1 className="m-0">{title}</h1>
        ) : size === "h2" ? (
          <h2 className="m-0">{title}</h2>
        ) : size === "h3" ? (
          <h3 className="m-0">{title}</h3>
        ) : size === "h4" ? (
          <h4 className="m-0">{title}</h4>
        ) : size === "h5" ? (
          <h5 className="m-0">{title}</h5>
        ) : size === "h6" ? (
          <h6 className="m-0">{title}</h6>
        ) : (
          <h1 className="m-0">{title}</h1>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: 0,
          maxWidth: "100%",
        }}
      >
        {actions}
      </div>
    </div>
  );
};

export default Header;

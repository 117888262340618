import { BasketCardActionProps } from "../Basket.types";
import { Icon } from "../../../modules/icon";

const BasketCardActions = ({
  id,
  isBookmarked,
  onBookmark,
  onDelete,
  onEdit,
}: BasketCardActionProps) => {
  return (
    <div className="flex-row basketcard-actions">
      <Icon
        title={isBookmarked ? "Unbookmark" : "Bookmark"}
        type="fa-solid fa-bookmark"
        color={isBookmarked ? "#da291c" : "black"}
        onClick={(event) => {
          event?.stopPropagation();
          onBookmark(id);
        }}
      />
      <Icon
        title="Edit"
        type="fa-solid fa-pen"
        onClick={(event) => {
          event?.stopPropagation();
          onEdit(id);
        }}
      />
      <Icon
        title="Delete"
        type="fa-solid fa-trash"
        onClick={(event) => {
          event?.stopPropagation();
          onDelete(id);
        }}
      />
    </div>
  );
};

export default BasketCardActions;

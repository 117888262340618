export interface LoaderProps {
  size?: LoaderSizeType;
  color?: string;
}

export type LoaderSizeType = "s" | "m" | "l" | "xl";

export interface LoaderStyleConfig {
  borderWidth: number;
  color: string;
  heightWidth: number;
}

export const defaultColor: string = "#ffffff";
export const defaultSize: LoaderSizeType = "m";

export const LoaderStyleMap: { [key in LoaderSizeType]: LoaderStyleConfig } = {
  s: {
    borderWidth: 3,
    heightWidth: 23,
    color: defaultColor,
  },
  m: {
    borderWidth: 5,
    heightWidth: 30,
    color: defaultColor,
  },
  l: {
    borderWidth: 8,
    heightWidth: 50,
    color: defaultColor,
  },
  xl: {
    borderWidth: 12,
    heightWidth: 100,
    color: defaultColor,
  },
};

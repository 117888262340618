import { useLocation, useNavigate } from "react-router";
import useBaseRequest from "../../api/BaseRequest";
import AuthGateway from "../../api/gateways/AuthGateway";
import { useToast } from "../../components/toast";
import { IUser } from "../../entities/IUser";
import { useAppDispatch } from "../../store";
import { authActions } from "../../store/auth/AuthReducer";

export function useLogin() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { success } = useToast();
  const { state } = useLocation();

  const onLoginSuccess = (data: IUser) => {
    dispatch(authActions.setUser(data));
    success(`Welcome, ${data.name}`);
    navigate(state?.returnTo || "/", { replace: true });
  };

  const { execute, loading } = useBaseRequest(AuthGateway.login, {
    onCompleted: onLoginSuccess,
  });

  return { logIn: execute, loading };
}

import { GenericForm } from "../../components/generic-form/GenericForm.types";
import { GenericFormValidator } from "../../components/generic-form/utility/GenericFormValidator";

export const loginForm: GenericForm<LoginDTO> = {
  username: {
    type: "input",
    name: "username",
    label: "Username",
    defaultValue: "",
    validators: [GenericFormValidator.required()],
  },
  password: {
    type: "password",
    name: "password",
    label: "Password",
    defaultValue: "",
    validators: [GenericFormValidator.required()],
  },
};

export interface LoginDTO {
  username: string;
  password: string;
}

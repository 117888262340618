import React, { ChangeEvent } from "react";
import { itemsPerPageOptions } from ".";
import { TableRow, Selection, Pagination } from "./Table.types";

export function useTable(
  rows: TableRow[],
  selection: Selection | false,
  pagination: Pagination | false
) {
  const [numberOfPages, setNumberOfPages] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState(
    itemsPerPageOptions[0]
  );

  React.useEffect(() => {
    if (!pagination) return;
    const noOfPages = Math.ceil(pagination.totalItems / itemsPerPage) || 1;
    setNumberOfPages(noOfPages);
  }, [pagination, itemsPerPage]);

  const setPage = (page: number) => {
    console.log("page", page);
    setCurrentPage(page);
    if (!!pagination && !!pagination.onPageChange) {
      pagination.onPageChange(page);
    }
  };

  const setItemsLimit = (limit: number) => {
    setItemsPerPage(limit);
    if (!!pagination && !!pagination.onItemsPerPageChange) {
      pagination.onItemsPerPageChange(limit);
    }
  };

  const onItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(event.target.value);
    setItemsLimit(value);
  };

  const setSelected = (selected: any[]) => {
    if (selection && selection.onSelectionChanged) {
      selection.onSelectionChanged(selected);
    }
  };

  const onMainCheckBoxChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (!selection || !selection.selected) return;
    if (event.target.checked && !selection.selected.length) {
      setSelected(rows.map((r) => r[0]));
    } else {
      setSelected([]);
    }
  };

  const onRowCheckBoxChanged = (
    event: ChangeEvent<HTMLInputElement>,
    id: number | string
  ) => {
    if (!selection || !selection.selected) return;
    if (event.target.checked) {
      if (!selection.selected.find((sr) => sr === id)) {
        setSelected([...selection.selected, id]);
      }
    } else {
      setSelected(selection.selected.filter((sr) => sr !== id));
    }
  };

  return {
    onMainCheckBoxChanged,
    onRowCheckBoxChanged,
    onItemsPerPageChange,
    itemsPerPage,
    currentPage,
    numberOfPages,
    setPage,
  };
}

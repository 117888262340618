import moment from "moment";
import React from "react";
import { useNavigate } from "react-router";
import useBaseRequest from "../../api/BaseRequest";
import DocumentGateway from "../../api/gateways/DocumentGateway";
import { QueryFilter } from "../../components/filters";
import { TableRow } from "../../components/table";
import { GetDocumentsDTO } from "../../entities/IDocument";
import { Icon } from "../../modules/icon";
import { useDocumentService } from "../../services/useDocumentService";
import { screens } from "../Navigation.types";
import { archiveTableHeaders } from "./Archive.types";

export function useArchive() {
  const navigate = useNavigate();
  const { getDocFileUrl } = useDocumentService();

  const [docTableData, setDocTableData] = React.useState<TableRow[]>([]);
  const [docPayload, setDocPayload] = React.useState<GetDocumentsDTO>({
    skip: 0,
    limit: 10,
    sort: {
      key: "createdAt",
      value: "DESC",
    },
  });
  const { data, execute, loading } = useBaseRequest(
    DocumentGateway.getDocuments,
    {
      autoFetch: true,
      initialPayload: docPayload,
      onCompleted: (data) => {
        const { documents } = data;
        const docTable: TableRow[] = [];
        documents.forEach((doc) => {
          docTable.push([
            doc.id,
            doc.metaData?.jmbg || doc.metaData?.mb || "",
            doc.metaData?.msisdn || "",
            doc.metaData?.signDate
              ? moment(doc.metaData?.signDate).format("DD.MM.YYYY")
              : "",
            doc.docType.title,
            doc.metaData?.archiveType || "",
            doc?.metaData?.boxId || "",
            moment(doc?.createdAt).format("DD.MM.YYYY"),
            doc.createdBy?.name || "",
            doc?.dealerCode === doc.createdById.toString()
              ? ""
              : doc.dealerCode || "",

            <div>
              <a
                target="_blank"
                rel="noreferrer"
                className="mr-3"
                href={
                  doc.files && doc.files.length
                    ? getDocFileUrl(doc.files[doc.files.length - 1].url)
                    : "/"
                }
              >
                <Icon type="fa-solid fa-file" color="black" size={16} />
              </a>
              <a href={`/documents/${doc.id}`}>
                <Icon type="fa-solid fa-circle-info" color="black" size={16} />
              </a>
            </div>,
          ]);
        });
        setDocTableData(docTable);
      },
    }
  );

  const onSearchSubmit = (text: string) => {
    const newPayload = { ...docPayload, textSearch: text };
    setDocPayload(newPayload);
    execute(newPayload);
  };

  const onDocumentPerPageChange = (docNum: number) => {
    const newPayload = { ...docPayload, limit: docNum };
    setDocPayload(newPayload);
    execute(newPayload);
  };

  const onPageChange = (page: number) => {
    const newPayload = {
      ...docPayload,
      skip: page > 0 ? (page - 1) * (docPayload.limit || 10) : 10,
    };
    setDocPayload(newPayload);
    execute(newPayload);
  };

  const onFilterChange = (queryFilters: QueryFilter[]) => {
    const newPayload = {
      ...docPayload,
      filters: queryFilters,
    };
    setDocPayload(newPayload);
    execute(newPayload);
  };

  const onSort = (key: string, order: "ASC" | "DESC") => {
    const newPayload = {
      ...docPayload,
      sort: { key, value: order },
    };
    setDocPayload(newPayload);
    execute(newPayload);
  };

  const onRowPress = (id: number) => {
    navigate(screens.documents + `/${id}`);
  };

  return {
    count: data?.count || 0,
    getDocuments: execute,
    loading,
    docTableData,
    onSearchSubmit,
    onDocumentPerPageChange,
    onPageChange,
    archiveTableHeaders,
    onFilterChange,
    onSort,
    onRowPress,
  };
}

import { useMemo } from "react";
import {
  LoaderProps,
  defaultSize,
  defaultColor,
  LoaderStyleConfig,
  LoaderStyleMap,
} from "./Loader.types";

export function useLoader({ size, color }: LoaderProps) {
  const loaderStyle: LoaderStyleConfig = useMemo(() => {
    const loaderSize = size || defaultSize;
    const loaderColor = color || defaultColor;
    const style = LoaderStyleMap[loaderSize];
    style.color = loaderColor;
    return style;
  }, [size, color]);

  return loaderStyle;
}

import { PageLayout } from "../../components/page-layout";

import useBaskets from "./useBaskets";
import BookmarkedBaskets from "./BookmarkedBaskets";
import AllBaskets from "./AllBaskets";
import CreateBasketModal from "./CreateBasketModal";
import UpdateBasketModal from "./UpdateBasketModal";
import DeleteBasketModal from "./DeleteBasketModal";

export function BasketsScreen() {
  const {
    baskets,
    bookmarkedBaskets,
    createBasket,
    creatingBasket,
    hideCreateBasketModalHandler,
    showCreateBasketModalHandler,
    isVisibleCreateBasketModal,
    createBasketForm,
    updateBasketForm,
    isVisibleUpdateBasketModal,
    setUpdateBasketID,
    hideUpdateBasketModalHandler,
    updateBasket,
    updatingBasket,
    hideDeleteBasketModalHandler,
    setDeleteBasketID,
    deleteBasket,
    deletingBasket,
    isVisibleDeleteBasketModal,
    toggleBookmark,
  } = useBaskets();
  return (
    <PageLayout title={"Baskets"}>
      <CreateBasketModal
        onDismiss={hideCreateBasketModalHandler}
        onSubmit={createBasket}
        creating={creatingBasket}
        isVisible={isVisibleCreateBasketModal}
        createBasketForm={createBasketForm}
      />
      <UpdateBasketModal
        onDismiss={hideUpdateBasketModalHandler}
        onSubmit={updateBasket}
        updating={updatingBasket}
        isVisible={isVisibleUpdateBasketModal}
        updateBasketForm={updateBasketForm}
      />
      <DeleteBasketModal
        onDismiss={hideDeleteBasketModalHandler}
        onDelete={deleteBasket}
        deleting={deletingBasket}
        isVisible={isVisibleDeleteBasketModal}
      />
      <div className="flex-column gap-3">
        <BookmarkedBaskets
          baskets={bookmarkedBaskets}
          onBookmark={(id) => {
            toggleBookmark(id);
          }}
          onDelete={(id) => {
            setDeleteBasketID(id);
          }}
          onEdit={(id) => {
            setUpdateBasketID(id);
          }}
        />
        <AllBaskets
          baskets={baskets}
          onBookmark={(id) => {
            toggleBookmark(id);
          }}
          onDelete={(id) => {
            setDeleteBasketID(id);
          }}
          onEdit={(id) => {
            setUpdateBasketID(id);
          }}
          showCreateBasketModalHandler={showCreateBasketModalHandler}
        />
      </div>
    </PageLayout>
  );
}

import { Filters } from "../../components/filters";
import { Column, Row } from "../../components/grid";
import { PageLayout } from "../../components/page-layout";
import { SearchInput } from "../../components/search-input";
import { Table } from "../../components/table";
import { archiveFilters } from "./Archive.types";
import { useArchive } from "./useArchive";

export function ArchiveScreen() {
  const {
    docTableData,
    count,
    onDocumentPerPageChange,
    onPageChange,
    onSearchSubmit,
    archiveTableHeaders,
    onFilterChange,
    loading,
    onSort,
    onRowPress,
  } = useArchive();
  return (
    <PageLayout title="Archive">
      <Row>
        <Column>
          <div className="mt-4 mb-1">
            <Filters filters={archiveFilters} onFilterChange={onFilterChange} />
          </div>
        </Column>
        <Column>
          <div className="mt-4 mb-1">
            <div className="d-flex flex-row my-1">
              <SearchInput
                placeHolder="Search documents..."
                className="ml-auto"
                onSearchSubmit={onSearchSubmit}
              />
            </div>
          </div>
        </Column>
      </Row>
      <Row>
        <Column>
          <div className="my-2">
            <Table
              loading={loading}
              data={docTableData}
              headers={archiveTableHeaders}
              selection={false}
              pagination={{
                totalItems: count,
                onItemsPerPageChange: onDocumentPerPageChange,
                onPageChange,
              }}
              sort={{ onSort }}
              onRowPress={onRowPress}
            />
          </div>
        </Column>
      </Row>
    </PageLayout>
  );
}

import { Route, Routes, Navigate } from "react-router-dom";
import { AuthGuard } from "../components/guard";
import { SideNav } from "../components/side-nav";
import { ArchiveScreen } from "./archive";
import { DocumentScreen } from "./document";
import { DocumentsScreen } from "./documents";
import { LoginScreen } from "./login";
import { screens } from "./Navigation.types";
import { NewDocumentScreen } from "./new-document";
import { SearchScreen } from "./search";
import { UploadDocumentScreen } from "./upload-document";
import { BasketsScreen } from "./baskets";
import BasketTasksScreen from "./basket-tasks/BasketTasksScreen";
import TaskScreen from "./task/TaskScreen";

export default function Navigation() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <AuthGuard>
            <SideNav />
          </AuthGuard>
        }
      >
        <Route index path="" element={<Navigate to={screens.documents} />} />
        <Route path={screens.documents}>
          <Route path="" element={<DocumentsScreen />} />
          <Route path=":id" element={<DocumentScreen />} />
        </Route>
        <Route
          path={screens.uploadDocument}
          element={<UploadDocumentScreen />}
        />
        <Route path={screens.newDocument} element={<NewDocumentScreen />} />
        <Route path={screens.baskets} element={<BasketsScreen />} />
        <Route path={screens.basketTasks} element={<BasketTasksScreen />} />
        <Route path={screens.archive} element={<ArchiveScreen />} />
        <Route path={screens.search} element={<SearchScreen />} />
        <Route path={screens.tasks} element={<TaskScreen />} />
        {/* <Route path={screens.document} element={<DocumentScreen />} /> */}
      </Route>
      <Route path={screens.login} element={<LoginScreen />} />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
}

import moment from "moment";
import { ChangeEvent } from "react";
import { Password } from "../password";
import { GenericFormControlProps } from "./GenericForm.types";
import { CheckBox } from "../checkbox";

export function GenericFormControl<T, P>({
  control,
  error,
  value,
  onChange,
  formState,
}: GenericFormControlProps<T, P>) {
  function onInputChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    onChange(value);
  }

  function onSelectChange(event: ChangeEvent<HTMLSelectElement>) {
    const value = event.target.value;
    onChange(value);
  }

  function onDateChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    onChange(new Date(value));
  }

  function onCheckedChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.checked;
    onChange(value);
  }

  function renderControl() {
    switch (control.type) {
      case "input":
        return (
          <input
            className={`form-control${error ? " is-invalid" : ""}`}
            value={value as any}
            onChange={onInputChange}
            disabled={
              !!control.disabledIf && control.disabledIf(formState)
                ? true
                : false
            }
          />
        );
      case "password":
        return (
          <Password
            value={value as any}
            onChange={onInputChange}
            className={`form-control${error ? " is-invalid" : ""}`}
          />
        );
      case "select":
        return (
          <select
            className={`form-select${error ? " is-invalid" : ""}`}
            value={value as any}
            onChange={onSelectChange}
            disabled={
              !!control.disabledIf && control.disabledIf(formState)
                ? true
                : false
            }
          >
            {control.options
              ? control.options.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))
              : null}
          </select>
        );
      case "date":
        return (
          <input
            type="date"
            className={`form-control${error ? " is-invalid" : ""}`}
            value={moment(value || new Date()).format("YYYY-MM-DD")}
            onChange={onDateChange}
            disabled={
              !!control.disabledIf && control.disabledIf(formState)
                ? true
                : false
            }
          />
        );
      case "checkbox":
        return <CheckBox checked={value as any} onChange={onCheckedChange} />;
      case "hidden":
        return <></>;
      default:
        console.warn(
          "Unsupported data type! Please consider adding custom FormControl"
        );
        return <></>;
    }
  }
  return renderControl();
}

import { ChangeEvent } from "react";
import assests from "../../config/assets";
import { Icon } from "../../modules/icon";
import { FilePreviewProps } from "./FilePreview.types";

export function FilePreview({
  type,
  height = 200,
  width = 200,
  url,
  name,
  enableEditing = false,
  enableCanceling = false,
  onFileChosen,
  onCancel,
}: FilePreviewProps) {
  const renderFile = () => {
    switch (type) {
      case "application/pdf":
        return (
          <iframe
            title="File preview"
            width={width}
            height={height}
            className="border rounded"
            src={url}
          />
        );

      case "image/png":
      case "image/jpeg":
        return (
          <img alt="File preview" src={url} height={height} width={width} />
        );
      default:
        return (
          <a href={url} target="_blank" rel="noreferrer">
            <Icon type="fa-solid fa-file" size={50} color={"black"} />
          </a>
        );
    }
  };

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (!file) return;
    if (onFileChosen) {
      onFileChosen(file);
    }
  };
  return (
    <div>
      <div className="d-flex flex-row align-items-center">
        {enableEditing ? (
          <div className="position-relative mr-3">
            <input
              onChange={onFileChange}
              type="file"
              style={{
                height: 24,
                width: 33,
                top: 2,
                zIndex: 2,
                opacity: 0,
                position: "absolute",
                left: 0,
                cursor: "pointer",
                fontSize: 0,
              }}
            />
            <img
              src={assests.images.reupload}
              height={24}
              width={33}
              alt="Reupload"
            />
          </div>
        ) : null}
        <label className="mr-2">{name}</label>
        {enableCanceling ? (
          <div className="ml-auto cursor-pointer" onClick={onCancel}>
            <Icon type="fa-solid fa-xmark" size={24} />
          </div>
        ) : null}
        {!enableEditing && !enableCanceling ? (
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            className="ml-auto cursor-pointer"
          >
            <Icon
              type="fa-solid fa-up-right-from-square"
              color="black"
              size={22}
            />
          </a>
        ) : null}
      </div>
      <div className="mt-3 text-center">{renderFile()}</div>
    </div>
  );
}

import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import useBaseRequest from "../../api/BaseRequest";
import DocumentGateway from "../../api/gateways/DocumentGateway";
import { GenericForm } from "../../components/generic-form/GenericForm.types";
import { useToast } from "../../components/toast";
import { IDocument } from "../../entities/IDocument";
import { MetaDataForm } from "../../entities/IMetaData";
import { useWindowDimensions } from "../../modules/window";
import { useDocumentService } from "../../services/useDocumentService";
import { screens } from "../Navigation.types";
import { TASK_SUCCESS_MESSAGES } from "../../entities/ITask";
import TaskGateway from "../../api/gateways/TaskGateway";
import { createTaskForm as createTaskFormDefault } from "./task/CreateTaskModal.types";

export function useDocument(id: string) {
  const [metaDataForm, setMetaDataForm] =
    React.useState<GenericForm<MetaDataForm> | null>(null);
  const [docFile, setDocFile] = React.useState<{
    uri: string;
    type: string;
    name: string;
    file: File | null;
    edited: boolean;
  } | null>(null);

  // states
  const [editing, setEditing] = React.useState(false);
  const { height: windowHeight } = useWindowDimensions();
  const [showDismissModal, setShowDismissModal] = React.useState(false);
  const navigate = useNavigate();
  const { success, danger } = useToast();

  // requests
  const { mapMetaDataRulesToForm, getDocFileUrl } = useDocumentService();

  const {
    data,
    loading,
    execute: fetch,
  } = useBaseRequest(DocumentGateway.getDocumentByIdAndMetaData, {
    initialPayload: { id },
    autoFetch: true,
    onCompleted: (data) => {
      if (!data) return;
      const form = mapMetaDataRulesToForm(
        data.metaDataRules,
        data.document.metaData
      );
      setMetaDataForm(form);
      setDefaultFile(data.document);
    },
  });

  const { execute: update, loading: updating } = useBaseRequest(
    DocumentGateway.updateDocument,
    {
      onCompleted: (data, payload) => {
        fetch({ id: payload.id });
        setEditing(false);
      },
    }
  );

  const { execute: dismiss } = useBaseRequest(DocumentGateway.dismissDocument, {
    onCompleted: (data) => {
      closeModal();
      navigate(screens.documents);
      success("Document deleted successfully.");
    },
  });

  // handlers
  const onEdit = () => {
    setEditing(true);
  };

  const onEditCancel = () => {
    setEditing(false);
    setDefaultFile(data?.document);
  };

  const setDefaultFile = (doc?: IDocument) => {
    const file =
      doc?.files && doc.files.length ? doc.files[doc.files.length - 1] : null;
    if (!file) {
      setDocFile(null);
      return;
    }
    const fileUrl = getDocFileUrl(file.url);
    setDocFile({
      uri: fileUrl,
      type: file.mimeType || "",
      name: file.name,
      file: null,
      edited: false,
    });
  };

  const onFileEditCancel = () => setDefaultFile(data?.document);
  const onFileEdit = (newFile: File) => {
    setDocFile({
      edited: true,
      type: newFile.type,
      name: newFile.name,
      file: newFile,
      uri: URL.createObjectURL(newFile),
    });
  };

  const onUpdateDoc = (metaData: MetaDataForm) => {
    const params: any = {
      id: data?.document?.id?.toString(),
      metaData,
    };
    if (!!docFile && docFile.file && docFile.edited) {
      params.file = docFile.file;
    }
    update(params);
  };

  const closeModal = () => setShowDismissModal(false);
  const openModal = () => setShowDismissModal(true);

  const onDismiss = () => {
    dismiss({ id: data?.document.id?.toString() || "" });
  };

  //Create Task
  const [isVisibleCreateTaskModal, setIsVisibleCreateTaskModal] =
    useState(false);
  const { execute: createTask, loading: creatingTask } = useBaseRequest(
    TaskGateway.createTask,
    {
      onCompleted: (data) => {
        success(TASK_SUCCESS_MESSAGES.created);
        setIsVisibleCreateTaskModal(false);
      },
      onError: (error) => {
        danger(error?.message || "Something went wrong.");
      },
    }
  );

  const onStartCreatingTask = () => {
    if (data) {
      if (data?.document.metaData?.documentCategory === "Business") {
        createTask({ documentId: data.document.id, taskTypeCode: "bus_c42" });
        return;
      }
      setIsVisibleCreateTaskModal(true);
    }
  };

  const createTaskForm = useMemo(() => {
    return {
      ...createTaskFormDefault,
      documentId: {
        ...createTaskFormDefault.documentId,
        defaultValue: data?.document.id || -1,
      },
      taskTypeCode: {
        ...createTaskFormDefault.taskTypeCode,
        options: createTaskFormDefault.taskTypeCode.options?.filter(
          (item) => item.value !== "bus_c42"
        ),
      },
    };
  }, [data?.document.id]);
  const hideCreateTaskModal = () => setIsVisibleCreateTaskModal(false);

  return {
    document: data?.document,
    metaDataForm,
    loading,
    windowHeight,
    onUpdateDoc,
    editing,
    onEdit,
    onEditCancel,
    docFile,
    onFileEdit,
    onFileEditCancel,
    showDismissModal,
    closeModal,
    openModal,
    onDismiss,
    updating,
    createTaskForm,
    isVisibleCreateTaskModal,
    onCreateTask: createTask,
    creatingTask,
    onStartCreatingTask,
    hideCreateTaskModal,
  };
}

import { Table } from "../../components/table";
import { searchArchiveData, searchArchiveHeaders } from "./Search.types";

export function SearchArchiveTable() {
  return (
    <div className="my-4 w-100">
      <Table
        headers={searchArchiveHeaders}
        data={searchArchiveData}
        selection={false}
        pagination={false}
      />
    </div>
  );
}

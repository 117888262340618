import { Filter } from "../../components/filters";
import { TableHeaders } from "../../components/table";

export const tableFilters: Filter[] = [
  {
    type: "single-choice",
    name: "Box ID",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    buildQuery: (filter, values) => {
      return {
        name: "metaData.boxId",
        type: "string",
        operator: values[0] === "yes" ? "ne" : "eq",
        value: null,
      };
    },
  },
  {
    type: "single-choice",
    name: "Archive Type",
    options: [
      { label: "Digital", value: "Digital" },
      { label: "Physical", value: "Physical" },
    ],
    buildQuery: (filter, values) => {
      return {
        name: "metaData.archiveType",
        type: "string",
        operator: "eq",
        value: values[0],
      };
    },
  },
  {
    type: "date-range",
    name: "Sign Date",
    buildQuery: (filter, values) => {
      return {
        name: "metaData.signDate",
        type: "dateArray",
        operator: "between",
        value: JSON.stringify(values),
      };
    },
  },
  {
    type: "date-range",
    name: "Upload Date",
    buildQuery: (filter, values) => {
      return {
        name: "createdAt",
        type: "dateArray",
        operator: "between",
        value: JSON.stringify(values),
      };
    },
  },
];

export const documentTableHeaders: TableHeaders[] = [
  { name: "ID", key: "id", sortable: true },
  { name: "JMBG/MB", key: "customerIdentityNumber" },
  { name: "MSISDN", key: "metaData.msisdn" },
  { name: "Sign Date", key: "metaData.signDate", sortable: true },
  { name: "Type", key: "metaData.documentType" },
  { name: "Archive", key: "metaData.archiveType" },
  { name: "Box ID", key: "metaData.boxId" },
  { name: "Upload Date", key: "createdAt", sortable: true },
  { name: "Uploaded By", key: "createdBy" },
  { name: "Actions", key: "action" },
];

export interface DocumentsTableProps {
  textSearch?: string;
}

export interface AddToBoxModalProps {
  show: boolean;
  onAdd: (boxId: string) => any;
  adding: boolean;
  documentIds: number[];
  onCancel: () => any;
}
